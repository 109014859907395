import { CIcon } from '~/icons/types'

export const ciLocate: CIcon = {
  name: 'locate',
  type: 'solid',
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 8.3C9.9 8.3 8.3 10 8.3 12c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7c0-2.1-1.6-3.7-3.7-3.7Z'
    },
    {
      d:
        'M22 11h-1.9c-.5-3.7-3.4-6.6-7.1-7.1V2c0-.6-.4-1-1-1s-1 .4-1 1v1.9c-3.7.5-6.6 3.4-7.1 7.1H2c-.6 0-1 .4-1 1s.4 1 1 1h1.9c.5 3.7 3.4 6.6 7.1 7.1V22c0 .6.4 1 1 1s1-.4 1-1v-1.9c3.7-.5 6.6-3.4 7.1-7.1H22c.6 0 1-.4 1-1s-.4-1-1-1Zm-10 7.1c-3.4 0-6.1-2.7-6.1-6.1 0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1 0 3.4-2.7 6.1-6.1 6.1Z'
    }
  ]
}
