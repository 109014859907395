import { CIcon } from '~/icons/types'

export const ciAngleLeft: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M7.94 13.06a1.5 1.5 0 0 1 0-2.12l5-5a1.5 1.5 0 0 1 2.12 2.12L11.122 12l3.94 3.94a1.5 1.5 0 0 1-2.122 2.12l-5-5Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'angle-left-solid',
  type: 'solid'
}
