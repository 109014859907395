






















































































































import FeedGrid from './FeedGrid.vue'
import RecentFeedMixin from './RecentFeedMixin.vue'
import FeedClassifiedEntry from './feed-entries/FeedClassifiedEntry.vue'
import FeedRecentButtons from './FeedRecentButtons.vue'
import LiveFeedControl from './LiveFeedControl.vue'
import CLocatorButton from '~/components/shared/configurable/location/locator/CLocatorButton.vue'
import CDragScroll from '~/components/shared/configurable/CDragScroll.vue'
import CShimmer from '~/components/shared/configurable/shimmer/CShimmer.vue'
import { capitalizeWords } from '~/utils/string'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { defineComponent } from '~/utils/nuxt3-migration'
import { ciChevronRight } from '~/icons/source/solid/chevron-right'

export default defineComponent({
  components: {
    FeedClassifiedEntry,
    FeedRecentButtons,
    CLocatorButton,
    FeedGrid,
    LiveFeedControl,
    CDragScroll,
    CShimmer
  },
  mixins: [RecentFeedMixin],
  setup() {
    return {
      ciChevronRight
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    randomShimmerWidth() {
      return Math.floor(Math.random() * 250) + 300 + 'px'
    }
  },
  methods: {
    moveDone() {
      // pause or start the fetching depending on where the scroller is
      if (!this.isLatestDesktop && !this.isIndexMobile && this.$refs.scroller) {
        if (this.$refs.scroller.scrollX >= 0 && !this.forcePause) {
          if (this.fetchInterval === null) {
            this.startFetching()
          }
        } else {
          this.clearFetchInterval()
        }
      }
    },
    afterTransitionEnd() {
      this.$nextTick(() => {
        if (this.$refs.scroller) {
          if (this.transitionName === 'empty') {
            this.$refs.scroller.scrollX = 0
          }
          this.$refs.scroller.calcDsWidth()
        }
      })
    },
    moreClicked() {
      this.$analytics.recordEvent({
        namespace: 'n_index_recents_more_clicked',
        action: 'click',
        label: this.isPc ? 'desktop' : 'mobile'
      })
    },
    setFetchInterval() {
      if (process.client) {
        // only set interval on client (else we got weird server intervals)

        this.fetchInterval = setInterval(() => {
          if (document) {
            // uncomment following code if we want stuff to run even when the window is not focused (but visible)
            // if (typeof document.hidden !== 'undefined') {
            //   if (document.hidden) {
            //     return false
            //   }
            // }

            if (typeof document.hasFocus() !== 'undefined') {
              if (!document.hasFocus()) {
                return
              }
            }
          }
          this.fetchFeed()
        }, this.refreshInterval * 1000)
      }
    },
    isVisible() {
      if (document) {
        // hide if window/tab is not visible
        if (typeof document.hidden !== 'undefined') {
          if (document.hidden) {
            return false
          }
        }
        if (typeof document.hasFocus() !== 'undefined') {
          if (!document.hasFocus()) {
            return
          }
        }
        const elem = this.$refs.feed
        const offset = 200
        let headerHeight = 0

        const desktopHeader = document.getElementById('header-section')
        if (desktopHeader) {
          headerHeight = desktopHeader.offsetHeight
        }

        const docViewTop = document.documentElement.scrollTop
        const docViewBottom = docViewTop + window.innerHeight

        let elemTop = headerHeight
        let offsetElem = elem
        if (offsetElem.offsetParent) {
          do {
            elemTop += offsetElem.offsetTop
            offsetElem = offsetElem.offsetParent
          } while (offsetElem)
        }

        if (elemTop < 0) {
          elemTop = headerHeight
        }

        const elemBottom = elemTop + elem.offsetHeight

        return (
          elemTop - offset <= docViewBottom &&
          elemBottom - headerHeight + offset >= docViewTop + headerHeight
        )
      }
      return false
    },
    triggerLocator() {
      this.$refs.locator && this.$refs.locator.showModal()
    },
    capitalizeWords(str: string) {
      return capitalizeWords(str)
    }
  }
})
