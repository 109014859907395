import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'
import { CategoryId } from '~/models/category/types'

export const partsSearchConfig: SearchConfig = Object.freeze({
  settings: {
    searchName: 'parts',
    viewType: {
      availableViewTypes: [ViewType.LIST, ViewType.GALLERY],
      defaultViewTypeDesktop: ViewType.LIST,
      defaultViewTypeMobile: ViewType.LIST
    },
    modifier: 'mobile-desktop',
    showDealsButton: true,
    searchbar: {
      autocompleteEndpoint: '/api/classifieds/query/',
      usesNuxtLinks: true
    },
    stats: {
      record: true
    },
    displayAds: true,
    isMapSearchBtnVisible: true
  },
  rootParams: {
    category: [CategoryId.PARTS]
  }
})
