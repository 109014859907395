import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { CategoryId, HierarchicalCategory } from '~/models/category/types'
import { invalidBodyError } from '../errors'
import { flattenTree } from '../formatters'
import { UnformattedTree } from '~/models/shared/tree/types'

@containerScoped()
export default class HierarchicalCategoryService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getQuickSearchCategoryMap(
    idOrIds: string | string[],
    rootId: CategoryId = CategoryId.CLASSIFIEDS,
    thirdLevelCategories: boolean = false
  ) {
    const ids = typeof idOrIds === 'string' ? [idOrIds] : idOrIds

    const { data: body } = await this.http.get(
      '/api/quick-search/categories/',
      {
        params: {
          category: ids,
          root: thirdLevelCategories ? rootId : null
        }
      }
    )

    if (!body || !body.data || !Array.isArray(body.data.categories)) {
      throw invalidBodyError(body)
    }

    if (thirdLevelCategories) {
      return new Map(
        body.data.categories.flatMap((c: any) => [
          ...flattenTree<HierarchicalCategory>(
            c,
            this.formatHierarchicalCategory
          ).entries()
        ])
      )
    }

    const { children: filteredSubcategories } = body.data.categories.find(
      (c: any) => c.children.length
    )

    return new Map(
      filteredSubcategories.flatMap((c: any) => [
        ...flattenTree<HierarchicalCategory>(
          c,
          this.formatHierarchicalCategory
        ).entries()
      ])
    )
  }

  async getCategoryMap(
    idOrIds: string | string[],
    rootId: CategoryId = CategoryId.CLASSIFIEDS,
    parentsLimit: CategoryId[] = []
  ): Promise<Map<string, HierarchicalCategory>> {
    const ids = typeof idOrIds === 'string' ? [idOrIds] : idOrIds

    const { data: body } = await this.http.get('/api/categories/', {
      params: {
        category: ids,
        root: rootId
      }
    })

    if (!body || !body.data || !Array.isArray(body.data.categories)) {
      throw invalidBodyError(body)
    }

    return this.createHierarchicalCategoryMap(
      body.data.categories,
      parentsLimit
    )
  }

  createHierarchicalCategoryMap(
    categories: any[],
    parentsLimit: CategoryId[] = []
  ) {
    const formatCat = (tree: UnformattedTree<any>) =>
      this.formatHierarchicalCategory(tree, parentsLimit)

    return new Map(
      categories.flatMap((c: any) => [
        ...flattenTree<HierarchicalCategory>(c, formatCat).entries()
      ])
    )
  }

  private formatHierarchicalCategory(
    c: any,
    parentsLimit?: CategoryId[]
  ): HierarchicalCategory {
    const ancestorIds = c.parent_ids || c.parentIds

    const parentId = ancestorIds && ancestorIds.length && ancestorIds[0]

    let hasChildren = Boolean(c.has_children || c.hasChildren)
    let childrenIds =
      c.children &&
      c.children.map(
        (child: any) => child && child.value && child.value.toString()
      )
    if (parentsLimit?.includes(parentId)) {
      hasChildren = false
      childrenIds = undefined
    }

    return {
      id: c.value && c.value.toString(),
      humanName: c.name,
      childrenIds,
      childrenCanBeRetrieved: hasChildren,
      parentId: parentId.toString(),
      ancestorIds: ancestorIds && ancestorIds.map((id: any) => id.toString()),
      selected: c.selected,
      unselectable: c.is_unselectable || c.isUnselectable,
      original: c.original
    }
  }
}
