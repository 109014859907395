import { ViewType } from '~/models/shared/types'
import { CategoryId } from '~/models/category/types'

export const vehiclesSearchConfig = {
  create(name: string) {
    return {
      settings: {
        searchName: name,
        viewType: {
          availableViewTypes: [ViewType.LIST, ViewType.GALLERY],
          defaultViewTypeDesktop: ViewType.LIST,
          defaultViewTypeMobile: ViewType.LIST
        },
        showDealsButton: true,
        searchbar: {
          autocompleteEndpoint: '/api/classifieds/query/',
          usesNuxtLinks: true
        },
        stats: {
          record: true
        },
        mobileListRowShowKeyFeatures: true,
        isVehiclesSearch: true,
        isMapSearchBtnVisible: true,
        isCompareButtonVisible: true,
        displayAds: true
      },
      rootParams: {
        // TODO: that's a bit too fragile, maybe change later
        category: [CategoryId[name.toUpperCase() as keyof typeof CategoryId]]
      }
    }
  }
}
