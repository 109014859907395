import { interopDefault } from '~/router/utils'
import { playgroundToolsRoutes } from '~/router/routes/shared/playground/tools'
import { playgroundDirectivesRoutes } from '~/router/routes/shared/playground/directives'
import { playgroundCompositionsRoutes } from '~/router/routes/shared/playground/compositions'
import { playgroundComponentsRoutes } from '~/router/routes/shared/playground/components'
import { addRouteMeta } from '~/utils/router'

export default [
  {
    name: '__playground',
    path: '/playground',
    component: () =>
      interopDefault(import('~/pages/shared/playground/index.vue')),
    children: [
      ...playgroundToolsRoutes,
      ...playgroundDirectivesRoutes,
      ...playgroundCompositionsRoutes,
      ...playgroundComponentsRoutes
    ].map(r => addRouteMeta(r, { noFooter: true })),
    meta: {
      noFooter: true
    }
  }
]
