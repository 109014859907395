import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import { StoreWithRootState } from '~/store/types'
import { UserType } from '~/models/user/types'
import { Permission } from '~/constants/permission'
import { AccessToggleName } from '~/models/user/access-toggles'
import { USER_NS } from '~/store/modules/shared/user/state'
import { storeToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class RouteGuardService {
  constructor(@inject(storeToken) private store: StoreWithRootState) {}

  public userIsOfType(userType: UserType): boolean {
    return this.store.getters[`${USER_NS}/is`](userType)
  }

  public userIsOfTypeDealer(): boolean {
    return this.store.getters[`${USER_NS}/isOfTypeDealer`]
  }

  public userIsAuditsUser(): boolean {
    return this.store.getters[`${USER_NS}/isAuditsUser`]
  }

  public userHasPermission(permission: Permission): boolean {
    return this.store.getters[`${USER_NS}/hasPermission`](permission)
  }

  public userHasAccessToggle(accessToggle: AccessToggleName): boolean {
    return this.store.getters[`${USER_NS}/userHasAccessTo`](accessToggle)
  }

  public userCanManageAnnouncements(): boolean {
    return (
      this.userIsOfType(UserType.ADMIN) &&
      this.userHasPermission(Permission.ADMIN_ANNOUNCEMENTS)
    )
  }

  public userCanManageBlacklist(): boolean {
    return (
      this.userIsOfType(UserType.ADMIN) &&
      this.userHasPermission(Permission.ADMIN_BLACKLIST)
    )
  }

  public userCanManageAdvertising(): boolean {
    return (
      this.userIsOfType(UserType.ADMIN) &&
      this.userHasPermission(Permission.ADMIN_MANAGE_INHOUSE_ADS)
    )
  }

  public userCanEditCategories(): boolean {
    return (
      this.userIsOfType(UserType.ADMIN) &&
      this.userHasPermission(Permission.ADMIN_CATEGORIES_EDIT)
    )
  }

  public userCanSeeStripePayments(): boolean {
    return (
      this.userCanPay() &&
      this.userHasAccessToggle(AccessToggleName.GUEST_CAR_STRIPE_CREDITS)
    )
  }

  public userCanPay(): boolean {
    return (
      this.userHasAccessToggle(AccessToggleName.PLOT_AGENT_PAYMENTS) ||
      this.userHasAccessToggle(AccessToggleName.GUEST_PAYMENTS) ||
      this.userIsOfTypeDealer()
    )
  }

  public userCanOffer(): boolean {
    return this.userHasAccessToggle(AccessToggleName.CLASSIFIED_OFFERS)
  }

  public userCanCreateClassified(): boolean {
    return this.userHasAccessToggle(AccessToggleName.CREATE_CLASSIFIEDS)
  }

  public userHasAccessToParking(): boolean {
    return this.userHasAccessToggle(AccessToggleName.PARKING)
  }

  public userHasAccessToNotifications(): boolean {
    return this.userHasAccessToggle(AccessToggleName.NOTIFICATIONS)
  }

  public userCanReceiveMessages(): boolean {
    return this.userHasAccessToggle(AccessToggleName.RECEIVE_MESSAGES)
  }

  public userCanSeeSubscriptions(): boolean {
    return this.userIsOfTypeDealer()
  }

  public userCanBeMarketplaceBuyer(): boolean {
    return (
      this.userHasAccessToggle(AccessToggleName.MARKETPLACE_BUYER) &&
      this.store.getters[`${USER_NS}/marketplaceOrdersCount`]
    )
  }

  public userCanBeMarketplaceSeller(): boolean {
    return this.userHasAccessToggle(AccessToggleName.MARKETPLACE_SELLER)
  }

  public userCanViewPricesHistory(): boolean {
    return this.userHasAccessToggle(
      AccessToggleName.PERK_VEHICLE_MODELS_PRICE_PAGE
    )
  }

  public userCanManageReports(): boolean {
    return (
      this.userIsOfType(UserType.ADMIN) &&
      this.userHasPermission(Permission.ADMIN_REPORTS)
    )
  }

  public userIsAnon(): boolean {
    return (
      this.userIsOfType(UserType.SINGLE) || this.userIsOfType(UserType.ANON)
    )
  }

  public userIsSingle(): boolean {
    return this.userIsOfType(UserType.SINGLE)
  }

  public userHasAccessToAccountPanel(): boolean {
    return !this.userIsAnon()
  }

  public userIsLoggedIn(): boolean {
    return !this.userIsAnon()
  }

  public userIsGuestWithIncompleteProfile(): boolean {
    return (
      this.userIsOfType(UserType.GUEST) &&
      !this.store.getters[`${USER_NS}/hasCompleteProfile`]
    )
  }

  public userCanTrade(): boolean {
    return this.userHasAccessToggle(AccessToggleName.TRADES)
  }

  public userCanUseInHouseAds(): boolean {
    return this.userHasAccessToggle(AccessToggleName.IN_HOUSE_ADS_ACCESS)
  }

  public userCanUseSaleRequestsBuy(): boolean {
    return this.userHasAccessToggle(AccessToggleName.SALE_REQUESTS_BUY)
  }

  public userCanUseSaleRequestsSell(): boolean {
    return this.userHasAccessToggle(AccessToggleName.SALE_REQUESTS_SELL)
  }

  public userHasAccessToWantedParts(): boolean {
    return this.userHasAccessToggle(AccessToggleName.WANTED_PARTS_ACCESS)
  }

  public userHasAudits(): boolean {
    return (
      !this.userIsAuditsUser() &&
      this.store.getters[`${USER_NS}/userHasAuditRequests`]
    )
  }

  public userHasOnlyOneOutlet(): boolean {
    return this.store.state.user.outletsCount === 1
  }
}
