import {
  vue3DirectiveBind,
  vue3DirectiveUnbind,
  vue3DirectiveComponentUpdated
} from '~/utils/nuxt3-migration'

export interface CFocusOptions {
  /**
   * Trap focus within the container element
   */
  trap?: boolean
  /**
   * Auto-focus the first focusable element
   */
  autofocus?: boolean
  /**
   * Focus the container element
   */
  focusable?: boolean
  /**
   * Only apply focus trap when the container becomes visible
   */
  active?: boolean
  /**
   * Don't return focus when trap is deactivated
   */
  noReturn?: boolean
}

// Focusable selector mimicking Tabbable library's functionality
const focusableElements = [
  'a[href]',
  'area[href]',
  'button:not([disabled]):not([aria-hidden])',
  'input:not([disabled]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex="-1"]):not([disabled]):not([aria-hidden])'
].join(',')

const directiveName = 'c_focus_trap'

// Store previously focused elements to restore focus when trap is deactivated
const focusHistory: Map<HTMLElement, HTMLElement> = new Map()

// Extend HTMLElement to include our custom property
declare global {
  interface HTMLElement {
    [directiveName]?: FocusManager
  }
}

class FocusManager {
  private el: HTMLElement
  private options: CFocusOptions
  private focusables: HTMLElement[] = []
  private observer: MutationObserver | null = null
  private onKeyDown: (e: KeyboardEvent) => void
  private isActive = false
  private previouslyFocusedElement: HTMLElement | null = null

  constructor(el: HTMLElement, options: CFocusOptions = {}) {
    this.el = el
    this.options = {
      trap: true,
      autofocus: true,
      focusable: false,
      active: true,
      noReturn: false,
      ...options
    }

    this.onKeyDown = this.handleKeyDown.bind(this)

    if (this.options.focusable) {
      this.setupFocusable()
    }

    if (this.options.trap && this.options.active) {
      setTimeout(() => this.activate(), 50) // Small delay to ensure the DOM is ready
    }

    // Watch for DOM changes to refresh focusable elements
    this.setupMutationObserver()
  }

  private setupFocusable() {
    if (!this.el.hasAttribute('tabindex')) {
      this.el.setAttribute('tabindex', '0')
    }
  }

  private setupMutationObserver() {
    this.observer = new MutationObserver(() => {
      if (this.isActive) {
        this.refreshFocusables()
      }
    })

    this.observer.observe(this.el, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['tabindex', 'disabled', 'aria-hidden']
    })
  }

  private refreshFocusables() {
    const candidates = Array.from(
      this.el.querySelectorAll(focusableElements)
    ) as HTMLElement[]

    // Filter out hidden elements and elements with display:none
    this.focusables = candidates.filter(el => {
      if (!el.offsetWidth && !el.offsetHeight) return false

      // Check for visibility and display
      const style = window.getComputedStyle(el)
      return !(style.visibility === 'hidden' || style.display === 'none')
    })
  }

  activate() {
    if (this.isActive) return

    this.refreshFocusables()

    // Store currently focused element to restore later
    if (!this.options.noReturn) {
      this.previouslyFocusedElement = document.activeElement as HTMLElement
      if (
        this.previouslyFocusedElement &&
        this.previouslyFocusedElement !== document.body
      ) {
        focusHistory.set(this.el, this.previouslyFocusedElement)
      }
    }

    this.isActive = true
    document.addEventListener('keydown', this.onKeyDown)

    if (this.options.autofocus) {
      this.autofocus()
    }
  }

  deactivate() {
    if (!this.isActive) return

    this.isActive = false
    document.removeEventListener('keydown', this.onKeyDown)

    // Restore focus to the previously focused element
    if (!this.options.noReturn) {
      const elementToFocus = focusHistory.get(this.el)
      if (elementToFocus && typeof elementToFocus.focus === 'function') {
        setTimeout(() => elementToFocus.focus(), 0)
      }
      focusHistory.delete(this.el)
    }
  }

  autofocus() {
    // Focus the first focusable element, or the container if focusable
    if (this.focusables.length > 0) {
      setTimeout(() => this.focusables[0].focus(), 0)
    } else if (this.options.focusable) {
      setTimeout(() => this.el.focus(), 0)
    }
  }

  private handleKeyDown(e: KeyboardEvent) {
    // Only handle Tab key presses
    if (e.key !== 'Tab') return

    // Make sure we have the latest focusable elements
    this.refreshFocusables()

    // If no focusable elements, trap all focus
    if (this.focusables.length === 0) {
      e.preventDefault()
      return
    }

    // If only one focusable element, always focus it
    if (this.focusables.length === 1) {
      e.preventDefault()
      this.focusables[0].focus()
      return
    }

    const firstFocusable = this.focusables[0]
    const lastFocusable = this.focusables[this.focusables.length - 1]
    const activeElement = document.activeElement

    // Handle Tab + Shift (backward tab)
    if (e.shiftKey) {
      if (activeElement === firstFocusable || activeElement === this.el) {
        e.preventDefault()
        lastFocusable.focus()
      }
    }
    // Handle Tab without Shift (forward tab)
    else if (activeElement === lastFocusable) {
      e.preventDefault()
      firstFocusable.focus()
    }
  }

  destroy() {
    this.deactivate()

    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }

    if (this.options.focusable && this.el.getAttribute('tabindex') === '0') {
      this.el.removeAttribute('tabindex')
    }
  }
}

// Directive implementation
const bind = (el: HTMLElement, binding: any) => {
  const options = binding.value || {}
  el[directiveName] = new FocusManager(el, options)
}

const componentUpdated = (el: HTMLElement, binding: any) => {
  const instance = el[directiveName]

  if (!instance) {
    bind(el, binding)
    return
  }

  // If the active state changed, activate or deactivate accordingly
  const newValue = binding.value || {}
  const oldValue = binding.oldValue || {}

  if (newValue?.active !== oldValue?.active) {
    if (newValue?.active) {
      instance.activate()
    } else {
      instance.deactivate()
    }
  }
}

const unbind = (el: HTMLElement) => {
  const instance = el[directiveName]

  if (instance) {
    instance.destroy()
    delete el[directiveName]
  }
}

export default {
  [vue3DirectiveBind]: bind,
  [vue3DirectiveComponentUpdated]: componentUpdated,
  [vue3DirectiveUnbind]: unbind
}
