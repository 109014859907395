var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dropdown',{ref:"dropdownTemplateRef",class:_vm.wrapperClass,style:(_vm.wrapperStyle),attrs:{"triggers":_vm.triggers,"placement":_vm.placement,"delay":_vm.delay,"popper-triggers":_vm.popperTriggers || _vm.triggers,"boundary":_vm.boundary,"handle-resize":"","arrow-overflow":_vm.arrowOverflow,"container":_vm.container,"disabled":_vm.disabled,"distance":_vm.distance,"skidding":_vm.skidding,"shift":_vm.shift,"shown":_vm.internalShown,"theme":("dropdown-" + _vm.size),"popper-class":[
    _vm.popperClass,
    {
      'no-arrow': _vm.noArrow
    }
  ],"auto-hide":_vm.autoHide,"auto-size":_vm.autoSize,"hide-triggers":_vm.hideTriggers,"popper-hide-triggers":_vm.popperHideTriggers,"show-group":_vm.showGroup,"dispose-timeout":_vm.disposeTimeout},on:{"show":function($event){return _vm.$emit('show')},"apply-show":function($event){return _vm.$emit('shown')},"hide":function($event){return _vm.$emit('hidden')},"apply-hide":function($event){return _vm.$emit('apply-hide')}},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{style:(_vm.contentStyle)},[(_vm.$slots.title)?_c('div',{staticClass:"tw-bg-grey-300 tw-font-medium tw-w-full tw-text-base",class:[{ 'tw-px-3 tw-py-2': !_vm.noPadding }, _vm.titleClass]},[_vm._t("title")],2):_vm._e(),_vm._v(" "),_c('div',{class:[
          { 'tw-px-3 tw-py-2': !_vm.noPadding },
          'tw-text-base',
          _vm.contentClass
        ]},[_vm._t("content")],2)])]},proxy:true}],null,true)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }