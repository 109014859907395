import { CIcon } from '~/icons/types'

export const ciUser: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.432-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'user',
  type: 'solid'
}
