





















import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  onBeforeUnmount,
  ref,
  toRefs
} from '~/utils/nuxt3-migration'
import { TableVerticalAlign } from '~/models/table'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import {
  ADMINUSER_NS,
  AdminUserState
} from '~/store/modules/shared/admin/user/state'

export default defineComponent({
  props: {
    maxHeight: {
      type: Number,
      default: null
    },
    tableClass: {
      type: [String, Array],
      default: () => []
    },
    striped: {
      type: Boolean,
      default: false
    },
    stickyHeader: {
      type: Boolean,
      default: false
    },
    stickyColumn: {
      // Currently only sets the first column as sticky
      type: Boolean,
      default: false
    },
    condensed: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    verticalAlign: {
      type: String as PropType<TableVerticalAlign>,
      default: TableVerticalAlign.MIDDLE
    },
    withBorder: {
      type: Boolean,
      default: true
    },
    mobileResponsive: {
      type: Boolean,
      default: false
    },
    mobileTemplateColumns: {
      type: String,
      required: false,
      default: 'auto 1fr auto'
    }
  },
  setup(props) {
    const {
      maxHeight,
      tableClass,
      striped,
      stickyColumn,
      condensed,
      hover,
      mobileResponsive,
      verticalAlign,
      stickyHeader
    } = toRefs(props)

    const stuckY = ref(false)
    const stuckX = ref(false)
    const sentinelYRef = ref(null)
    const sentinelXRef = ref(null)
    const tableContainerRef = ref(null)
    let observerY: IntersectionObserver
    let observerX: IntersectionObserver

    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const isAdmin = computed(() => userGetters('isAdmin'))

    const { state: adminUserState } = useNamespacedStore<AdminUserState>(
      ADMINUSER_NS
    )
    const isSuas = computed(() => Boolean(adminUserState.adminExtras?.suas))

    onMounted(() => {
      if (stickyHeader.value && sentinelYRef.value) {
        observerY = new IntersectionObserver(
          ([record]) => {
            const targetInfo = record.boundingClientRect
            const rootBoundsInfo = record.rootBounds

            if (targetInfo.bottom < rootBoundsInfo.top) {
              stuckY.value = true
            }

            if (
              targetInfo.bottom >= rootBoundsInfo.top &&
              targetInfo.bottom < rootBoundsInfo.bottom
            ) {
              stuckY.value = false
            }
          },
          {
            threshold: [0],
            root: maxHeight.value ? tableContainerRef.value : undefined
          }
        )

        observerY.observe(sentinelYRef.value)
      }

      if (stickyColumn && sentinelXRef.value && tableContainerRef.value) {
        observerX = new IntersectionObserver(
          ([record]) => {
            const targetInfo = record.boundingClientRect
            const rootBoundsInfo = record.rootBounds

            if (targetInfo.right < rootBoundsInfo.left) {
              stuckX.value = true
            }

            if (
              targetInfo.right >= rootBoundsInfo.left &&
              targetInfo.right < rootBoundsInfo.right
            ) {
              stuckX.value = false
            }
          },
          {
            threshold: [0],
            root: tableContainerRef.value
          }
        )

        observerX.observe(sentinelXRef.value)
      }
    })

    onBeforeUnmount(() => {
      if (observerX && sentinelYRef.value) {
        observerX.unobserve(sentinelYRef.value)
      }
      if (observerY && sentinelXRef.value) {
        observerX.unobserve(sentinelXRef.value)
      }
    })

    const style = computed(() => {
      function maxHeightStyle() {
        if (maxHeight.value) {
          return {
            maxHeight: `${maxHeight.value}px`
          }
        }
        return {}
      }
      return { ...maxHeightStyle() }
    })

    const sentinelYClasses = computed(() => {
      const arr = ['sentinel-y']
      if (maxHeight.value || !stickyHeader.value) {
        arr.push('contained')
      } else if (isAdmin.value || isSuas.value) {
        arr.push('admin')
      }
      return arr
    })

    const tableClasses = computed(() => {
      function stripedClasses() {
        return striped.value ? ['striped'] : []
      }
      function stickyColumnClasses() {
        return stickyColumn.value ? ['sticky-column'] : []
      }
      function stickyHeaderClasses() {
        const arr = []

        if (stickyHeader.value) {
          arr.push('sticky-header')
        }
        if (maxHeight.value || !stickyHeader.value) {
          arr.push('contained')
        } else if (isAdmin.value || isSuas.value) {
          arr.push('admin')
        }
        return arr
      }
      function condensedClasses() {
        return condensed.value ? ['condensed'] : []
      }
      function hoverClasses() {
        return hover.value ? ['hover'] : []
      }
      function responsiveClasses() {
        return mobileResponsive.value ? ['responsive'] : []
      }
      function stickyClasses() {
        const arr = []

        if (stuckY.value) {
          arr.push('stuck-y')
        }
        if (stuckX.value) {
          arr.push('stuck-x')
        }
        return arr
      }
      function verticalAlignClasses() {
        switch (verticalAlign.value) {
          case TableVerticalAlign.TOP: {
            return ['vertical-align', 'top']
          }
          case TableVerticalAlign.MIDDLE: {
            return ['vertical-align', 'middle']
          }
          case TableVerticalAlign.BOTTOM: {
            return ['vertical-align', 'bottom']
          }
          default: {
            return []
          }
        }
      }

      return [
        ...verticalAlignClasses(),
        ...hoverClasses(),
        ...stripedClasses(),
        ...stickyColumnClasses(),
        ...stickyHeaderClasses(),
        ...condensedClasses(),
        ...stickyClasses(),
        ...responsiveClasses(),
        tableClass.value
      ]
    })

    return {
      style,
      tableClasses,
      sentinelYRef,
      sentinelXRef,
      tableContainerRef,
      sentinelYClasses
    }
  }
})
