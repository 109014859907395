










import { computed, defineComponent, PropType } from '~/utils/nuxt3-migration'
import { TableRecordVariant } from '~/models/app/table'

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<TableRecordVariant>,
      default: null
    },
    clickable: {
      type: Boolean,
      default: false
    },
    mobileGridTemplateAreas: {
      type: Array,
      required: false,
      default: () => []
    },
    mobileGridTemplateColumns: {
      type: String,
      required: false,
      default: 'auto 1fr auto'
    }
  },
  setup(props, { emit }) {
    const rowClasses = computed(() => {
      function variantClasses() {
        if (!props.variant) {
          return []
        }
        return [props.variant]
      }

      function responsiveClasses() {
        return props.mobileGridTemplateAreas.length ? ['responsive'] : []
      }

      return [...variantClasses(), ...responsiveClasses()]
    })

    const rowStyle = computed(() => {
      if (props.mobileGridTemplateAreas.length) {
        return {
          gridTemplateAreas:
            props.mobileGridTemplateAreas.map(item => `"${item}"`).join(' ') ||
            undefined,
          gridTemplateColumns: props.mobileGridTemplateColumns
        }
      }
      return undefined
    })

    const handleClick = () => {
      if (!props.clickable) return
      emit('click')
    }

    return { rowClasses, handleClick, rowStyle }
  }
})
