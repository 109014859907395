import {
  useContext,
  VueI18n,
  Vue,
  getCurrentInstance
} from '~/utils/nuxt3-migration'

export function useI18n(): VueI18n {
  const { app } = useContext()
  const { i18n } = app

  const instance = getCurrentInstance()
  const vm = instance?.proxy || new Vue({})

  // @ts-ignore
  return {
    ...i18n,
    // @ts-ignore
    locale: i18n.locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm)
  }
}
