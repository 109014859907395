import { CIcon } from '~/icons/types'

export const ciChevronRight: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M16.81 10.94a1.5 1.5 0 0 1 0 2.12l-7.5 7.5a1.5 1.5 0 0 1-2.12-2.12L13.628 12l-6.44-6.44A1.5 1.5 0 1 1 9.31 3.44l7.5 7.5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'chevron-right',
  type: 'solid'
}
