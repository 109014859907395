import { computed, ref, Ref } from '~/utils/nuxt3-migration'

import { ciCheckCircle } from '~/icons/source/regular/check-circle'
import { ciTimesCircle } from '~/icons/source/regular/times-circle'
import { ciExclamationTriangle } from '~/icons/source/regular/exclamation-triangle'

export function useFormComponent(
  labelClass: Ref<object | string | string[]> = ref(''),
  hasError: Ref<boolean> = ref(false),
  success: Ref<boolean> = ref(false),
  warning: Ref<boolean> = ref(false),
  type: string = 'input'
) {
  const controlClasses = computed(() => {
    if (hasError.value) {
      return [
        'focus:tw-ring-red-400',
        'focus:tw-border-red-400',
        'hover:tw-border-red-400',
        'tw-border-red-400',
        'tw-border-solid'
      ]
    } else if (success.value) {
      return [
        'focus:tw-ring-green-500',
        'focus:tw-border-green-500',
        'hover:tw-border-green-500',
        'tw-border-green-500',
        'tw-border-solid',
        { 'tw-text-grey-900': type !== 'select' }
      ]
    } else if (warning.value) {
      return [
        'focus:tw-ring-amber-500',
        'focus:tw-border-amber-500',
        'hover:tw-border-amber-500',
        'tw-border-amber-500',
        'tw-border-solid',
        { 'tw-text-grey-900': type !== 'select' }
      ]
    }

    return [
      'focus:tw-ring-blue-500',
      'focus:tw-border-blue-500',
      'hover:tw-border-blue-500',
      'tw-border-grey-300',
      'tw-border-solid',
      { 'tw-text-grey-900': type !== 'select' }
    ]
  })

  const labelClasses = computed(() => [
    'tw-font-medium',
    'tw-text-grey-900',
    'tw-inline-flex',
    'aria-required:tw-font-bold',
    "aria-required:after:tw-content-['*']",
    'aria-required:after:tw-select-none',
    labelClass.value
  ])

  const helperTextClasses = computed(() => {
    let colorClass = 'tw-text-grey-600'
    if (hasError.value) {
      colorClass = 'tw-text-red-600'
    } else if (success.value) {
      colorClass = 'tw-text-green-600'
    } else if (warning.value) {
      colorClass = 'tw-text-amber-600'
    }

    return ['tw-mt-2', 'tw-text-sm', 'tw-font-medium', colorClass]
  })

  const helperTextIcon = computed(() => {
    if (hasError.value) {
      return ciTimesCircle
    } else if (success.value) {
      return ciCheckCircle
    } else if (warning.value) {
      return ciExclamationTriangle
    }
    return null
  })

  return { controlClasses, labelClasses, helperTextClasses, helperTextIcon }
}
