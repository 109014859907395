import { Corner } from '~/models/layout'
import { GetterTreeWithRootState } from '~/store/types'
import { PageState } from './state'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'

export default {
  pageIsExplicitAndRequiresConsent(state, getters): boolean {
    const consent = state.consent
    if (!consent) {
      return false
    }

    return !getters.consentIsConfirmed(consent.name)
  },
  consentIsConfirmed: (_state, _getters, _rootState, rootGetters) => (
    consentName: string
  ) => {
    return Boolean(
      rootGetters[`${STORAGE_NS}/getItem`](`consent.${consentName}`)
    )
  },
  bottomRightCornerElements: state => state.cornerElements[Corner.BOTTOM_RIGHT]
} as GetterTreeWithRootState<PageState>
