import { inject } from 'tsyringe'
import { Route } from 'vue-router/types/router'
import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { THREE_SECONDS_MS, ONE_SECOND_MS } from '~/constants/duration'
import { pageViewRecordFunctionCacheKeys } from '~/constants/telemetry/page-view-recorder'
import { MeasurementId } from '~/models/analytics'
import { NamespacedStore } from '~/models/store/types'
import AnalyticsService from '~/services/AnalyticsService'
import FunctionCache from '~/services/cache/FunctionCache'
import StatsService from '~/services/stats/StatsService'
import {
  ClassifiedViewState,
  CLASSIFIED_VIEW_NS
} from '~/store/modules/shared/classifieds/view/state'
import { StoreWithRootState } from '~/store/types'
import { UserState, USER_NS } from '~/store/modules/shared/user/state'
import { getRouteMetaItem } from '~/utils/router'
import { getNamespacedStore } from '~/utils/store'
import { storeToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
@ClientOnly
export default class PageViewRecorder {
  private userStore: NamespacedStore<UserState>
  private classifiedViewStore: NamespacedStore<ClassifiedViewState>

  constructor(
    @inject(AnalyticsService) private analytics: AnalyticsService,
    @inject(StatsService) private statsService: StatsService,
    @inject(storeToken) store: StoreWithRootState,
    @inject(FunctionCache) private functionCache: FunctionCache
  ) {
    this.userStore = getNamespacedStore(store, USER_NS)
    this.classifiedViewStore = getNamespacedStore(store, CLASSIFIED_VIEW_NS)
  }

  recordPluginPageView() {
    const measurementIds: MeasurementId[] = []

    const ga4MeasurementId = this.analytics.getOwnGa4MeasurementIdFromEnv()
    ga4MeasurementId && measurementIds.push(ga4MeasurementId)

    this.analytics.recordPageView(measurementIds)
  }

  recordMiddlewarePageView(route: Route) {
    const isIframeRoute = getRouteMetaItem(route, 'isIframe')
    if (isIframeRoute || route.params.noAnalytics) {
      return
    }

    const measurementIds: MeasurementId[] = []

    const ownGa4MeasurementId = this.analytics.getOwnGa4MeasurementIdFromEnv()
    ownGa4MeasurementId && measurementIds.push(ownGa4MeasurementId)

    this.debouncedRecordMiddlewarePageView(measurementIds, {
      url: route.fullPath
    })
  }

  get debouncedRecordMiddlewarePageView() {
    const { middleware } = pageViewRecordFunctionCacheKeys
    return this.functionCache.cache(middleware, () =>
      this.analytics.createDebouncedPageViewRecordFunction(ONE_SECOND_MS)
    )
  }

  get debouncedRecordPageViewForFacetChange() {
    const { facetChange } = pageViewRecordFunctionCacheKeys
    return this.functionCache.cache(facetChange, () =>
      this.analytics.createDebouncedPageViewRecordFunction(THREE_SECONDS_MS)
    )
  }

  recordPageViewForFacetChange(url: string) {
    const measurementIds = []

    const ownGa4MeasurementId = this.analytics.getOwnGa4MeasurementIdFromEnv()
    ownGa4MeasurementId && measurementIds.push(ownGa4MeasurementId)

    this.debouncedRecordPageViewForFacetChange(measurementIds, {
      url
    })
  }

  recordStatClassifiedPageView() {
    const classifiedId = this.classifiedViewStore.state.classified?.id
    if (!classifiedId) {
      return
    }

    const classifiedIsOwn = this.classifiedViewStore.getters('isOwn')
    const userIdAdmin = this.userStore.getters('isAdmin')
    if (classifiedIsOwn || userIdAdmin) {
      return
    }

    this.statsService.record('events.clsfds.view', [classifiedId])
  }
}
