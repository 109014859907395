import {
  AdCampaignErrors,
  AdCampaignForm,
  AdForm,
  AdFormErrors,
  CampaignAd,
  QuickAd,
  QuickAdFormErrors
} from '~/models/advertising/types'
import { SchemaField } from '~/models/form/types'

export const ADVERTISE_FORM_NS = 'advertise/form'

export interface AdvertiseFormState {
  loading: boolean
  campaignValues: AdCampaignForm
  campaignErrors: AdCampaignErrors
  campaignSchema: SchemaField[]
  campaignAds: CampaignAd[]
  newAdValues: AdForm
  newAdErrors: AdFormErrors
  newAdSchema: SchemaField[]
  quickAd: QuickAd
  quickAdErrors: QuickAdFormErrors
  quickAdSchema: { [key: string]: SchemaField }
  name: string
}
function getDefaultState(): AdvertiseFormState {
  return {
    loading: false,
    campaignValues: {},
    campaignErrors: {},
    campaignSchema: [],
    campaignAds: [],
    newAdValues: {},
    newAdErrors: {},
    newAdSchema: [],
    quickAd: {
      inHouseAdCampaign: undefined,
      inHouseAd: undefined,
      inHouseAdItem: undefined,
      values: {}
    },
    quickAdErrors: {},
    quickAdSchema: {},
    name: ''
  }
}

export default getDefaultState
