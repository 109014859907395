import {
  SingleClassifiedAnalyticsResult,
  ClassifiedsAnalyticsOverviewResult
} from '~/models/analytics/classified-result'
import { toCamelCase } from '~/utils/object'
import {
  AnalyticsOutlets,
  AnalyticsPanelSubPanel,
  AnalyticsPanelSubPanelData,
  AnalyticsSection,
  AnalyticsSectionPanel,
  AnalyticsSubFilter
} from '~/models/analytics/classifieds'
import { formatPopularityVisualization } from '~/services/popularity/formatters'

export function formatOverviewResponse(
  o: any
): ClassifiedsAnalyticsOverviewResult {
  return {
    dateAvailableFrom: o.date_available_from,
    monthsAvailable: o.months_available,
    marketSections: o.market_sections?.map(formatOverviewSection),
    userSections: o.user_sections?.map(formatOverviewSection),
    userCategoriesRoot: o.user_categories_root,
    outlets: o.outlets && formatOverviewOutlets(o.outlets),
    processIsPending: o.process_is_pending
  }
}

export function formatOverviewOutlets(o: any): AnalyticsOutlets {
  return {
    filterKey: o.filter_key,
    urlArgName: o.url_arg_name,
    type: o.type,
    name: o.name,
    values: o.values
  }
}

export function formatOverviewSection(s: any): AnalyticsSection {
  return {
    filters: s.filters,
    name: s.name,
    type: s.type,
    panels: s.panels.map(formatSectionPanel),
    subFilters: s.sub_filters.map(formatOverviewSectionSubFilter)
  }
}

export function formatOverviewSectionSubFilter(s: any): AnalyticsSubFilter {
  return {
    filters: s.filters,
    name: s.name,
    type: s.type,
    urlArgName: s.url_arg_name,
    values: s.values
  }
}

export function formatSingleClassifiedAnalyticsResponse(
  r: any
): SingleClassifiedAnalyticsResult {
  return {
    plot: toCamelCase(r.plot),
    classified: r.classified,
    totals: r.totals && {
      buyItClicks: r.totals.buy_it_clicks,
      parked: r.totals.parked,
      views: r.totals.views
    }
  }
}

export function formatSectionPanelData(d: any): AnalyticsPanelSubPanelData {
  return {
    subPanels: (d.sub_panels || []).map(formatSubPanel)
  }
}

export function formatSubPanel(s: any): AnalyticsPanelSubPanel {
  return {
    visualization: formatPopularityVisualization(s.visualization),
    type: s.type
  }
}

export function formatSectionPanel(p: any): AnalyticsSectionPanel {
  return toCamelCase(p)
}
