import { CIcon } from '~/icons/types'

export const ciYoutube: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M23.063 12.012c0 1.85-.165 3.538-.352 4.86a3.76 3.76 0 0 1-3.358 3.215c-1.833.179-4.422.362-7.353.362s-5.52-.183-7.353-.362a3.76 3.76 0 0 1-3.358-3.214 35.024 35.024 0 0 1-.351-4.861c0-1.851.164-3.538.35-4.861a3.76 3.76 0 0 1 3.36-3.214A76.53 76.53 0 0 1 12 3.574c2.931 0 5.52.183 7.353.363A3.76 3.76 0 0 1 22.71 7.15c.187 1.323.352 3.01.352 4.86Z',
      fill: '#F03'
    },
    {
      d: 'M9.469 15.469 15.563 12 9.468 8.531v6.938Z',
      fill: '#FFF'
    }
  ],
  name: 'youtube',
  type: 'solid'
}
