import { VTooltip } from 'floating-vue'
import { vue3DirectiveBind, vue3DirectiveUpdate } from '~/utils/nuxt3-migration'

interface DirectiveBinding {
  value: any
  oldValue: any
  modifiers: any
}

interface FloatingVueOptions {
  content?: string
  theme?: string
  popperClass?: string
  triggers?: string[]
  delay?: number | { show: number; hide: number }
  placement?: string
  html?: boolean
}

type TooltipPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

interface CTooltipOptions {
  content?: string
  theme?: 'dark' | 'light'
  size?: 'md' | 'lg'
  triggers?: string[]
  delay?: number | { show: number; hide: number }
  placement?: TooltipPlacement
  html?: boolean
}

function transformOptions(
  value: string | CTooltipOptions | null | undefined
): FloatingVueOptions {
  // Handle null, undefined or empty string cases
  if (value === null || value === undefined) {
    return { content: '' }
  }

  if (typeof value === 'string') {
    return { content: value }
  }

  // Create a new object that matches FloatingVueOptions
  const options: FloatingVueOptions = {
    content: value.content,
    triggers: value.triggers,
    delay: value.delay,
    placement: value.placement,
    html: value.html
  }

  // Handle size transformation
  if (value.size) {
    options.popperClass = `v-popper--size-${value.size}`
  }

  // Handle theme transformation
  if (value.theme) {
    options.theme = value.theme === 'light' ? 'tooltip-light' : 'tooltip'
  }

  return options
}

const CTooltip = {
  ...VTooltip,
  [vue3DirectiveBind]: (el: HTMLElement, binding: DirectiveBinding) => {
    binding.value = transformOptions(binding.value)
    VTooltip.bind(el, binding)
  },
  [vue3DirectiveUpdate]: (el: HTMLElement, binding: DirectiveBinding) => {
    binding.value = transformOptions(binding.value)
    VTooltip.update(el, binding)
  }
}

export default CTooltip
