import { interopDefault } from '~/router/utils'

export const playgroundDirectivesRoutes = [
  {
    name: '__playground_directives',
    path: '/playground/directives',
    redirect: { name: '__playground' }
  },
  {
    name: '__playground_directives_c_focus',
    path: '/playground/directives/c-focus',
    component: () =>
      interopDefault(import('~/pages/shared/playground/directives/c-focus.vue'))
  }
]
