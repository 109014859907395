import Vue, { DirectiveOptions } from 'vue'
import CTooltip from '~/directives/c-tooltip'
import CVisible from '~/directives/c-visible'
import CClosePopper from '~/directives/c-close-popper'
import CClickOutside from '~/directives/c-click-outside'
import CFocus from '~/directives/c-focus'

export default () => {
  Vue.directive('c-tooltip', CTooltip as DirectiveOptions)
  Vue.directive('c-visible', CVisible as DirectiveOptions)
  Vue.directive('c-close-popper', CClosePopper as DirectiveOptions)
  Vue.directive('c-click-outside', CClickOutside)
  Vue.directive('c-focus', CFocus as DirectiveOptions)
}
