import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  NewAdSchema,
  CampaignResult,
  CampaignSchemaResult,
  AdCampaignForm,
  AdForm,
  AdResult,
  CampaignAdResult,
  CampaignAdsResult,
  CampaignAd,
  NewAdItemSchema,
  CampaignAdItemResult,
  AdAssetsResult,
  AdItemForm,
  AdItemResult,
  CampaignsResult,
  AdsResult,
  CampaignAdItem,
  CampaignAdItemPublishResult,
  AdBalanceResult,
  AdItemStatus,
  AdActionResult,
  AdAsset,
  AdItemsResult,
  PlacementUnitsResult,
  AdsForecastingResult,
  ForcastingInputData,
  QuickAdForm,
  QuickAd,
  QuickAdItemSchema,
  AdDebitResult
} from '~/models/advertising/types'
import { VueRouter } from '~/utils/nuxt3-migration'
import { toSnakeCase } from '~/utils/snake-case'
import { HttpStatus } from '~/constants/http'
import { AxiosProgressEvent } from '~/models/http'
import { noop } from '~/utils/function'
import {
  formatAdFormTimes,
  formatAdvertiseResponse
} from '~/services/ads/formatters'
import { StripeSession } from '~/models/payments/stripe'
import { format } from '~/utils/date'
import { getAdDashboardQueryFilters } from '~/utils/advertising'
import { Route } from 'vue-router/types/router'
import { vueRouterToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class AdvertiseService {
  constructor(
    @inject(vueRouterToken) private router: VueRouter,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  public fetchCampaigns(
    page?: number | string,
    route?: Route
  ): Promise<CampaignsResult> {
    const routeToUse = route || this.router.currentRoute
    const pg = page || routeToUse.query?.cpg?.toString() || 1
    const queryFilters = getAdDashboardQueryFilters(routeToUse)
    return this.requestBuilder
      .request('get', `/api/in-house-ads/campaigns/`)
      .params({
        pg,
        ...queryFilters
      })
      .send()
  }

  public fetchAds(
    route?: Route,
    campaign?: any,
    page?: number | string
  ): Promise<AdsResult> {
    const routeToUse = route || this.router.currentRoute

    const queryFilters = getAdDashboardQueryFilters(routeToUse)
    const pg = page || routeToUse.query?.apg?.toString() || 1
    const queryCampaign = routeToUse.query?.campaign
    return this.requestBuilder
      .request('get', `/api/in-house-ads/ads/`)
      .params({
        campaigns: campaign || queryCampaign,
        pg,
        'current-status': routeToUse.query.ad_status,
        ...queryFilters
      })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public fetchAdItems(
    route?: Route,
    campaign?: any,
    ad?: any,
    page?: number
  ): Promise<AdItemsResult> {
    const routeToUse = route || this.router.currentRoute
    const queryFilters = getAdDashboardQueryFilters(routeToUse)

    const pg = page || routeToUse.query?.apg?.toString() || 1
    const queryCampaign = routeToUse.query?.campaign
    const queryAd = routeToUse.query?.ad

    return this.requestBuilder
      .request('get', `/api/in-house-ads/ads/items/`)
      .params({
        campaigns: campaign || queryCampaign,
        ads: ad || queryAd,
        pg,
        'current-status': routeToUse.query.item_status,
        ...queryFilters
      })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public fetchAdminAds(
    pg?: string | number,
    status?: AdItemStatus,
    account?: number,
    userQuery?: string | number
  ): Promise<AdsResult> {
    return this.requestBuilder
      .request('get', `/api/admin/in-house-ads/ads/`)
      .params({
        pg,
        'current-status': status,
        'ad-account': account,
        user: userQuery
      })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public approveAd(itemId: number | string): Promise<AdActionResult> {
    return this.requestBuilder
      .request('post', `/api/admin/in-house-ads/item/${itemId}/status/`)
      .validate(body => body)
      .map(body => body)
      .send()
  }

  public rejectAd(
    itemId: number | string,
    reason: string
  ): Promise<AdActionResult> {
    return this.requestBuilder
      .request('delete', `/api/admin/in-house-ads/item/${itemId}/status/`)
      .data({ rejection_reason: reason })
      .validate(body => body)
      .map(body => body)
      .send()
  }

  public getCampaignSchema(): Promise<CampaignSchemaResult> {
    let url = '/api/in-house-ads/campaign/'
    const campaignId = this.router.currentRoute.params?.campaign

    if (campaignId) {
      url = `/api/in-house-ads/campaign/${campaignId}/`
    }

    return this.requestBuilder.request('get', url).send()
  }

  public postNewQuickAd(form: QuickAdForm): Promise<QuickAd> {
    return this.requestBuilder
      .request('post', '/api/in-house-ads/quick-create/')
      .data({ ...toSnakeCase(formatAdFormTimes(form)) })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public updateQuickAdItem(
    form: QuickAdForm,
    itemId: number
  ): Promise<QuickAd> {
    return this.requestBuilder
      .request('put', `/api/in-house-ads/quick-create/item/${itemId}/edit/`)
      .data({ ...toSnakeCase(formatAdFormTimes(form)) })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public postNewCampaign(form: AdCampaignForm): Promise<CampaignResult> {
    return this.requestBuilder
      .request('post', '/api/in-house-ads/campaign/')
      .data(form)
      .send()
  }

  public updateCampaign(
    form: AdCampaignForm,
    campaignId: number | string
  ): Promise<CampaignResult> {
    return this.requestBuilder
      .request('put', `/api/in-house-ads/campaign/${campaignId}/`)
      .data(form)
      .send()
  }

  public getCampaignAds(
    campaignId: number | string
  ): Promise<CampaignAdsResult> {
    return this.requestBuilder
      .request('get', `/api/in-house-ads/campaign/${campaignId}/ads/`)
      .validate(body => body && body.data && body.data.ads)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public getAdSchema(): Promise<NewAdSchema> {
    return this.requestBuilder
      .request('get', '/api/in-house-ads/campaign/ad/')
      .validate(body => body)
      .send()
  }

  public getQuickAdSchema(): Promise<NewAdSchema> {
    return this.requestBuilder
      .request('get', '/api/in-house-ads/quick-create/')
      .validate(body => body)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public getQuickAdSchemaForItem(
    itemId: number | string
  ): Promise<QuickAdItemSchema> {
    return this.requestBuilder
      .request('get', `/api/in-house-ads/quick-create/item/${itemId}/edit/`)
      .validate(body => body)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public getAdItemSchema(category?: number[]): Promise<NewAdItemSchema> {
    const adId = this.router.currentRoute.params?.ad
    return this.requestBuilder
      .request('get', `/api/in-house-ads/${adId}/item/`)
      .params({ category })
      .validate(body => body && body.data && body.data.schema)
      .send()
  }

  public getCampaignAdSchemaAndValues(
    adId: number | string
  ): Promise<CampaignAdResult> {
    return this.requestBuilder
      .request('get', `/api/in-house-ads/ad/${adId}/`)
      .validate(
        body => body && body.data && body.data.schema && body.data.values
      )
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public getCampaignAdItemSchemaAndValues(
    adId: number | string,
    itemId: number | string,
    category?: number[]
  ): Promise<CampaignAdItemResult> {
    return this.requestBuilder
      .request('get', `/api/in-house-ads/${adId}/item/${itemId}/`)
      .params({ category })
      .validate(
        body => body && body.data && body.data.schema && body.data.values
      )
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public postNewAd(form: AdForm): Promise<AdResult> {
    const campaignId = this.router.currentRoute.params?.campaign
    return this.requestBuilder
      .request('post', `/api/in-house-ads/campaign/${campaignId}/ads/new/`)
      .data({ ...toSnakeCase(formatAdFormTimes(form)) })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public duplicateAd(id: string | number, name: string): Promise<AdResult> {
    return this.requestBuilder
      .request('post', `/api/in-house-ads/${id}/duplicate/`)
      .data({ name })
      .send()
  }

  public updateAd(form: AdForm, adId: number | string): Promise<CampaignAd> {
    return this.requestBuilder
      .request('put', `/api/in-house-ads/ad/${adId}/`)
      .data({ ...toSnakeCase(formatAdFormTimes(form)) })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public deleteAd(adId: number | string): Promise<CampaignAd> {
    return this.requestBuilder
      .request('delete', `/api/in-house-ads/ad/${adId}/`)
      .validate(body => body.status === HttpStatus.OK)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public deleteAdItem(
    adId: number | string,
    itemId: number | string
  ): Promise<CampaignAd> {
    return this.requestBuilder
      .request('delete', `/api/in-house-ads/${adId}/item/${itemId}`)
      .validate(body => body.status === HttpStatus.OK)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public deleteAds(adIds: number[] | string[]): Promise<CampaignAd> {
    return this.requestBuilder
      .request('delete', `/api/in-house-ads/ads/`)
      .params({ ads: adIds })
      .validate(body => body.status === HttpStatus.OK)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public deleteCampaign(campaignId: number | string): Promise<CampaignAd> {
    return this.requestBuilder
      .request('delete', `/api/in-house-ads/campaign/${campaignId}/`)
      .validate(body => body.status === HttpStatus.OK)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public deleteCampaigns(
    campaignIds: number[] | string[]
  ): Promise<CampaignAd> {
    return this.requestBuilder
      .request('delete', `/api/in-house-ads/campaigns/`)
      .params({ campaigns: campaignIds })
      .validate(body => body.status === HttpStatus.OK)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public postNewAdItem(form: AdItemForm): Promise<AdItemResult> {
    const adId = this.router.currentRoute.params?.ad
    return this.requestBuilder
      .request('post', `/api/in-house-ads/${adId}/item`)
      .data({ ...toSnakeCase(form) })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public updateAdItem(form: AdItemForm): Promise<CampaignAdItem> {
    const adId = this.router.currentRoute.params?.ad
    const adItemId = this.router.currentRoute.params?.item
    return this.requestBuilder
      .request('put', `api/in-house-ads/${adId}/item/${adItemId}`)
      .data({ ...toSnakeCase(form) })
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public publishAdItem(): Promise<CampaignAdItemPublishResult> {
    const adId = this.router.currentRoute.params?.ad
    const adItemId = this.router.currentRoute.params?.item
    return this.requestBuilder
      .request('post', `api/in-house-ads/${adId}/item/${adItemId}/publish`)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public postAdAsset(
    file: File,
    bannerType: string,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void = noop
  ): Promise<AdAsset> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('banner_type', bannerType)

    return this.requestBuilder
      .request('post', '/api/in-house-ads/assets/')
      .data(formData)
      .onUploadProgress(progressEvent => onUploadProgress(progressEvent))
      .send()
  }

  public getAdsAssets(): Promise<AdAssetsResult> {
    return this.requestBuilder
      .request('get', '/api/in-house-ads/assets/')
      .validate(body => body && body.data && body.data.assets)
      .send()
  }

  public getAdBalance(): Promise<AdBalanceResult> {
    return this.requestBuilder
      .request('get', '/api/in-house-ads/overview/')
      .validate(body => body && body.data)
      .send()
  }

  public getAdDebit(): Promise<AdDebitResult> {
    return this.requestBuilder
      .request('get', '/api/in-house-ads/account/debits/')
      .validate(body => body && body.data)
      .send()
  }

  public topUpBalance(
    amount: number,
    requestInvoice: boolean,
    unit?: string,
    itemId?: string | number
  ): Promise<StripeSession> {
    // fallback urls
    const cancelUrl =
      window.location.origin +
      this.router.resolve({
        name: this.router.currentRoute.name || '__advertising_wallet',
        query: {
          ...this.router.currentRoute?.query,
          cancel: '1',
          success: undefined,
          unit,
          item: unit && itemId ? (itemId as string) : undefined
        }
      }).href
    const successUrl =
      window.location.origin +
      this.router.resolve({
        name: this.router.currentRoute.name || '__advertising_wallet',
        query: {
          ...this.router.currentRoute?.query,
          success: '1',
          cancel: undefined,
          unit,
          item: unit && itemId ? (itemId as string) : undefined
        }
      }).href

    return this.requestBuilder
      .request('post', `/api/in-house-ads/account/top-up/`)
      .data({
        request_invoice: requestInvoice,
        amount,
        cancel_url: cancelUrl,
        success_url: successUrl
      })
      .send()
  }

  public getUnitOptions(): Promise<PlacementUnitsResult> {
    return this.requestBuilder
      .request('get', `/api/in-house-ads/placement-units/`)
      .send()
  }

  public getForecasting(
    input: ForcastingInputData
  ): Promise<AdsForecastingResult> {
    const {
      categories,
      fromDate,
      toDate,
      locations,
      makes,
      budget,
      units
    } = input
    return this.requestBuilder
      .request('get', `/api/in-house-ads/oracle/reach/`)
      .params({
        categories,
        from_date: format(fromDate, 'd/M/yyyy'),
        to_date: format(toDate, 'd/M/yyyy'),
        locations,
        makes,
        budget,
        units
      })
      .send()
  }
}
