import { Vue } from '~/utils/nuxt3-migration'
import { ActionResult } from '~/models/shared/result'
import { ActionTreeWithRootState } from '~/store/types'
import { SET_NO_CREDITS_FOR_CLASSIFIED, SET_PENDING } from './mutation-types'
import { SearchActionsState } from './state'
import { HttpStatus } from '~/constants/http'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'

export default {
  async performAction(
    { commit },
    { action, classifiedId }: { action: Function; classifiedId?: number }
  ) {
    try {
      const data: ActionResult = await action()
      if (data && data.message) {
        const { message } = data
        this.$snackbar && this.$snackbar.success(message)
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error

      if (!errorMessage) {
        this.$logger.captureError(error)
        this.$snackbar &&
          // @ts-ignore
          this.$snackbar.error(this.$i18n.t('something went wrong'))
        return
      }

      if (
        error.response?.status === HttpStatus.NO_CREDITS ||
        errorMessage.includes('credits')
      ) {
        // @ts-ignore
        const msg = this.$i18n?.t('buy credits::promo') as string
        this.$snackbar &&
          this.$snackbar.error(errorMessage, {
            time: 5000,
            action: {
              text: msg,
              href: this.$dep(LegacyUrlService).getBuyCreditsUrl()
            }
          })
        if (classifiedId) {
          commit(SET_NO_CREDITS_FOR_CLASSIFIED, null)
          await Vue.nextTick()
          commit(SET_NO_CREDITS_FOR_CLASSIFIED, classifiedId)
        }
      } else {
        this.$snackbar && this.$snackbar.error(errorMessage)
      }
    } finally {
      commit(SET_PENDING, false)
    }
  }
} as ActionTreeWithRootState<SearchActionsState>
