import {
  FieldChange,
  IGNORE_HISTORY_FIELDS,
  SchemaResult
} from '~/models/classified/form'
import { FormState } from './state'
import { Vue } from '~/utils/nuxt3-migration'
import {
  ADD_TO_HISTORY,
  CLEAR_ERROR,
  SET_ALL_CATEGORIES,
  SET_BASE_CATEGORY,
  SET_CATEGORY_ID,
  SET_CATEGORY_IDS,
  SET_FIELD_ERRORS_DATA,
  SET_FIELD_VALUE,
  SET_FIELD_VALUES_DATA,
  SET_LOADING,
  SET_PAGE,
  SET_SCHEMA_DATA,
  SET_SCHEMA_EXTRAS,
  SET_SCHEMA_LOADING,
  SET_TOP_ALERT,
  SET_USER_TELEPHONES
} from './mutation-types'
import { AxiosError } from 'axios'
import { CLASSIFIED_EDIT_NS } from '../edit/state'
import { HttpStatus } from '~/constants/http'
import { ActionTreeWithRootState } from '~/store/types'
import ClassifiedEditService from '~/services/classified/edit/ClassifiedEditService'
import FormService from '~/services/form/FormService'
import ScrollService from '~/services/scroll/ScrollService'
import IncompleteClassifiedService from '~/services/form/IncompleteClassifiedService'
import { noop } from '~/utils/function'
import { USER_NS } from '~/store/modules/shared/user/state'

export interface FieldOptions {
  multilingual: {
    abbreviation: string
  }
  fromHistory: boolean
}

export default {
  loadSchemaAndFieldValues({ dispatch }) {
    return Promise.all([dispatch('loadSchema'), dispatch('loadFieldValues')])
  },
  async loadSchema({ state, commit }) {
    const formService = this.$dep(FormService)

    try {
      commit(SET_SCHEMA_LOADING, true)
      const { data } = await formService.getSchema(
        state.categoryId!,
        state.classifiedId!,
        state.fieldValues
      )
      const { schema, category, extras }: SchemaResult = data
      if (
        state.rootCategoryId &&
        !category.categoryIds.includes(state.rootCategoryId)
      ) {
        this.$error({
          statusCode: 404
        })
      }
      commit(SET_SCHEMA_EXTRAS, extras)
      commit(SET_SCHEMA_DATA, schema)
      commit(SET_CATEGORY_IDS, category.categoryIds)
    } catch (error) {
      const { error: errorMessage, status } =
        error.response && error.response.data
      return this.$error({ message: errorMessage, status })
    } finally {
      commit(SET_SCHEMA_LOADING, false)
    }
  },
  async loadFieldValues({ state, commit }) {
    const formService = this.$dep(FormService)
    const route = this.$router.currentRoute

    try {
      const { data, page } = await formService.getFieldValues(state.categoryId!)
      const { classified, allCategories } = data

      const fieldValues = route.query.incomplete
        ? { ...classified.fieldValues, ...classified.incompleteFieldValues }
        : classified.fieldValues

      commit(SET_FIELD_VALUES_DATA, fieldValues)
      commit(SET_ALL_CATEGORIES, allCategories)
      commit(SET_PAGE, page)
    } catch (error) {
      if (error.response) {
        const { error: errorMessage, status } = error.response.data
        this.$error({ message: errorMessage, statusCode: status })
      } else {
        this.$logger.captureError(error)
        return this.$error({ statusCode: 500 })
      }
    }
  },
  fieldsChanged({ dispatch }, fields: FieldChange[] = []) {
    for (const field of fields) {
      const { name, value, options } = field
      dispatch('fieldChanged', {
        field: { name, value, options }
      })
    }
  },
  fieldChanged({ state, commit }, { field }) {
    const { name, value, options } = field as FieldChange

    commit(CLEAR_ERROR, { name, options })

    if (
      process.client &&
      !IGNORE_HISTORY_FIELDS.includes(name) &&
      value !== state.fieldValues[name] &&
      !options?.fromHistory
    ) {
      const historyOptions = { ...options, fromHistory: true }
      const redoValue = Array.isArray(value) ? [...value] : value
      const redoTo = { name, value: redoValue, options: historyOptions }

      const langAbbreviation = historyOptions.multilingual?.abbreviation

      let undoValue = state.fieldValues[name]
      if (langAbbreviation) {
        undoValue =
          (state.fieldValues[name] &&
            state.fieldValues[name][langAbbreviation]) ||
          undefined
      }
      const undoTo = {
        name,
        value: Array.isArray(undoValue) ? [...undoValue] : undoValue,
        options: historyOptions
      }

      commit(ADD_TO_HISTORY, { undoTo, redoTo })
    }

    commit(SET_FIELD_VALUE, { name, value, options })
  },
  changeFieldValue({ commit }, { name, value, options }: FieldChange) {
    // silent change without calling schema or adding ot history
    commit(CLEAR_ERROR, { name, options })
    commit(SET_FIELD_VALUE, { name, value, options })
  },
  changeFieldFromHistory({ commit }, { name, value, options }: FieldChange) {
    // same with changeFieldValue for now but needed this way in order to differentiate from other actiosns
    // because components subscribe to this one
    commit(CLEAR_ERROR, { name, options })
    commit(SET_FIELD_VALUE, { name, value, options })
  },
  async validateClient({ state, getters }): Promise<boolean> {
    let firstKeyWithError = null

    for (const v of state.clientValidators) {
      if (!v.validator() && !firstKeyWithError) {
        firstKeyWithError = v.key
        // not using break here in order for it to run every validator
      }
    }

    if (firstKeyWithError && process.client) {
      await Vue.nextTick()
      const scrollService = this.$dep(ScrollService)
      const fieldElement = document.getElementById(
        getters.getFieldIdByName(firstKeyWithError)
      )

      const firstErrorElement = fieldElement?.querySelector('.c-form-error')

      let offset = -200

      if (firstKeyWithError.includes('color') && window.innerWidth > 991) {
        offset = -280
      } else if (firstKeyWithError.includes('description')) {
        offset = -400
      }
      scrollService.scrollTo(firstErrorElement || fieldElement, {
        offset
      })

      // false = not valid, true = valid
      // only return false if the element is actually visible, else what's the point?
      return !firstErrorElement
    }

    return true
  },
  async submitNewClassified(
    { state, dispatch, commit, getters, rootGetters },
    tab?: string
  ) {
    commit(SET_LOADING, true)

    const formService = this.$dep(FormService)
    const incompleteClassifiedKey = this.$router.currentRoute.query.incomplete

    try {
      const { classified, message } = await formService.createNewClassified(
        state.categoryId!,
        {
          ...state.fieldValues,
          form_key: incompleteClassifiedKey
        }
      )

      await this.$snackbar.success(message, {
        classes: ['above-floating-button-global']
      })

      // Reload the current user if they are anonymous to ensure they are treated as a single user from now on
      if (rootGetters[`${USER_NS}/isSingleOrAnon`]) {
        await dispatch(`${USER_NS}/loadUser`, null, { root: true })
      }

      if (
        // @ts-ignore
        classified.sale_requests?.eligible &&
        getters.shouldRedirectUserToSaleRequestsPage
      ) {
        this.$router.push({
          name: '__sale_requests_classified',
          params: { id: classified.id.toString() }
        })
      } else if (classified.user?.requires_payment) {
        this.$router.push({
          path: `/classifieds/${classified.id.toString()}/edit/charges/`
        })
      } else if (tab) {
        this.$router.push({
          path: `/classifieds/${classified.id.toString()}/edit/${tab}/`
        })
      } else {
        this.$router.push({
          path: `/classifieds/${classified.id.toString()}/edit/`
        })
      }
    } catch (error) {
      const response = error?.response

      if (
        response &&
        response.status === HttpStatus.TOO_MANY_CLASSIFIEDS_DEALER
      ) {
        dispatch('handleRedirect', response.data.data.classifieds_limit)
      } else if (response && response.status === HttpStatus.LOCKED) {
        await dispatch(
          'showTelephoneVerificationModal',
          response.data.data.telephones
        )
      } else {
        dispatch('handleSubmitError', error)
      }
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async submitEditClassified(
    { state, commit, dispatch },
    { scroll } = { scroll: false }
  ) {
    const [formService, scrollService] = this.$deps(FormService, ScrollService)

    commit(SET_LOADING, true)

    try {
      const { message } = await formService.editClassified(
        state.classifiedId!,
        state.fieldValues
      )
      this.$snackbar.success(message, {
        classes: ['above-floating-button-global']
      })
      commit(SET_TOP_ALERT, {
        message: formService.getSuccessfulEditMessage(),
        variant: 'success'
      })
      if (scroll) {
        scrollService.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }

      dispatch(`${CLASSIFIED_EDIT_NS}/loadEditInfo`, state.classifiedId, {
        root: true
      })
      commit(SET_FIELD_ERRORS_DATA, {})
    } catch (error) {
      const response = error?.response
      if (response && response.status === HttpStatus.LOCKED) {
        await dispatch(
          'showTelephoneVerificationModal',
          response.data.data.telephones
        )
      } else {
        dispatch('handleSubmitError', error)
      }
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async submitCategoryChange(
    { state, commit, dispatch },
    category: number | number[]
  ) {
    if (!state.classifiedId) {
      return
    }
    commit(SET_LOADING, true)
    try {
      const [formService] = this.$deps(FormService)
      const { message, categories } = await formService.submitCategoryChange(
        state.classifiedId!,
        category
      )

      commit(SET_ALL_CATEGORIES, categories)

      this.$snackbar.success(message, {
        classes: ['above-floating-button-global']
      })
    } catch (error) {
      dispatch('handleSubmitError', error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async makeCategoryIdPrimary({ state, commit, dispatch }, category: number) {
    commit(SET_LOADING, true)
    const [formService] = this.$deps(FormService)

    try {
      const { message } = await formService.makeCategoryIdPrimary(
        state.classifiedId!,
        category
      )
      commit(SET_CATEGORY_ID, category)
      commit(SET_BASE_CATEGORY, category)

      this.$snackbar.success(message, {
        classes: ['above-floating-button-global']
      })

      dispatch('loadSchema')
    } catch (error) {
      dispatch('handleSubmitError', error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async loadCopyFieldValues({ commit, dispatch }, classifiedId) {
    try {
      const { data } = await this.$dep(ClassifiedEditService).getCopyInfo(
        classifiedId
      )
      const {
        classified: { fieldValues }
      } = data
      commit(SET_FIELD_VALUES_DATA, fieldValues)
      await dispatch('loadSchema')
      commit(SET_PAGE, {
        // @ts-ignore
        title: `${this.$i18n.t('copy')} ${classifiedId}`
      })
    } catch (error) {
      error.message = `Failed to copy classified with id ${classifiedId}. ${error}`
      this.$logger.captureError(error)
    }
  },
  showTelephoneVerificationModal({ commit }, telephones) {
    commit(SET_USER_TELEPHONES, telephones)
  },
  handleSubmitError({ commit, getters }, error: AxiosError) {
    const { response } = error

    if (!response) {
      // there is no error response, the app probably broke
      this.$logger.captureError(error)
      this.$error({ statusCode: 500 })
      return
    }

    const [formService, scrollService] = this.$deps(FormService, ScrollService)
    const {
      fieldErrors,
      message,
      status,
      reason
    } = formService.formatFormError(response)
    switch (status) {
      case HttpStatus.TOO_MANY_REQUESTS: {
        message && this.$snackbar.error(message)
        break
      }

      case HttpStatus.FORBIDDEN: {
        const reasonId = reason?.id
        reasonId && window.location.replace(`/b/${reasonId}`)
        break
      }

      case HttpStatus.TOO_MANY_CLASSIFIEDS_DEALER:
      case HttpStatus.TOO_MANY_CLASSIFIEDS: {
        window.location.replace(`/too-many-classifieds/`)
        break
      }

      case HttpStatus.UNPROCESSABLE_ENTITY: {
        if (!fieldErrors) {
          return
        }
        commit(SET_FIELD_ERRORS_DATA, fieldErrors)
        // scroll into the first field with error
        const fieldErrorNames = Object.keys(fieldErrors)
        for (const field of getters.getAllFields) {
          if (fieldErrorNames.includes(field.name)) {
            const firstErrorElement = document.getElementById(
              getters.getFieldIdByName(field.name)
            )
            firstErrorElement &&
              scrollService.scrollTo(firstErrorElement, { offset: -100 })
            break
          }
        }
        break
      }

      default: {
        this.$logger.captureError(error)
        // @ts-ignore
        const defaultErrorMessage = this.$i18n.t('something went wrong')
        this.$snackbar.error(message || defaultErrorMessage)
        break
      }
    }
  },
  handleRedirect(_, classifiedsLimit) {
    this.$router.push({
      name: '__blacklist_too_many_classifieds',
      query: { limit: classifiedsLimit }
    })
  },
  undo({ state, getters, dispatch }): void {
    if (!getters.canUndo) {
      return
    }

    if (state.history[state.historyActiveIndex]) {
      dispatch(
        'changeFieldFromHistory',
        state.history[state.historyActiveIndex].undoTo
      )
      state.historyActiveIndex = state.historyActiveIndex - 1

      dispatch('loadSchema')
    }
  },
  redo({ state, getters, dispatch }): void {
    if (!getters.canRedo) {
      return
    }

    const nextIndex = state.historyActiveIndex + 1
    if (state.history[nextIndex]) {
      dispatch('changeFieldFromHistory', state.history[nextIndex].redoTo)
      state.historyActiveIndex = nextIndex
      dispatch('loadSchema')
    }
  },
  sendIncompleteClassifiedData({ state, rootGetters }) {
    const userIsEligibleForIncompleteClassifieds =
      rootGetters['user/isManager'] || rootGetters['user/isGuest']
    const categoryId = state.fieldValues.category
    const hasMultipleCategories = categoryId?.length > 1

    if (
      process.server ||
      !categoryId ||
      state.editMode ||
      !userIsEligibleForIncompleteClassifieds ||
      hasMultipleCategories
    ) {
      return
    }

    const [incompleteClassifiedService] = this.$deps(
      IncompleteClassifiedService
    )

    incompleteClassifiedService
      .submitIncompleteClassified({
        form: state.fieldValues,
        categoryId
      })
      .catch(noop)
  }
} as ActionTreeWithRootState<FormState>
