import { CategoryId } from '~/models/category/types'

export const comparableRootCategoryIds: CategoryId[] = [
  CategoryId.PLOT,
  // CategoryId.JOBS,
  CategoryId.XYMA,
  CategoryId.PARTS,
  CategoryId.CARS,
  CategoryId.BIKES,
  CategoryId.BOATS,
  CategoryId.BICYCLES,
  CategoryId.TRUCKS,
  CategoryId.TRAILERS,
  CategoryId.TAXIS,
  CategoryId.CARAVANS,
  CategoryId.MOTORHOMES,
  CategoryId.RADIOCONTROLS,
  CategoryId.GOKARTS,
  CategoryId.WATERSPORTS,
  CategoryId.SNOWSPORTS,
  CategoryId.AIRSPORTS,
  CategoryId.VANS,
  CategoryId.BUSES,
  CategoryId.BUILDERS,
  CategoryId.SEMITRUCKS,
  CategoryId.SEMITRAILERS,
  CategoryId.TRACTORS,
  CategoryId.FORKLIFTS
]
