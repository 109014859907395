import { GetterTreeWithRootState } from '~/store/types'
import { DealersMapState } from './state'

export default {
  getFacetsCount(state) {
    let activeFilters = 0
    if (state.params.q) {
      activeFilters += 1
    }
    if (state.params.ca && state.params.ca.length > 0) {
      activeFilters += 1
    }
    if (state.params.postcode) {
      activeFilters += 1
    }
    return activeFilters
  },
  categoriesCounts(state) {
    if (!state.categoriesCounts) {
      return {}
    }

    return state.categoriesCounts.reduce(
      (acc: Record<number, number>, category) => {
        const categoryId = Number(category.value)
        acc[categoryId] = category.count

        return acc
      },
      {}
    )
  }
} as GetterTreeWithRootState<DealersMapState>
