import { CategoryId } from '~/models/category/types'
import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'

export const plotMapSearchConfig: SearchConfig = Object.freeze({
  settings: {
    viewType: {
      availableViewTypes: [ViewType.LIST, ViewType.GALLERY],
      defaultViewTypeMobile: ViewType.LIST,
      defaultViewTypeDesktop: ViewType.GALLERY
    },
    modifier: 'mobile-desktop',
    searchName: 'plot',
    searchbar: {
      autocompleteEndpoint: '/api/classifieds/query/',
      usesNuxtLinks: true
    },
    isCompareButtonVisible: true,
    disableShowSellers: false,
    displayAds: true
  },
  // showBreadcrumbs: true
  rootParams: {
    category: [CategoryId.PLOT]
  }
})

export const plotSearchConfig: SearchConfig = Object.freeze({
  settings: {
    viewType: {
      availableViewTypes: [ViewType.LIST, ViewType.GALLERY],
      defaultViewTypeMobile: ViewType.LIST,
      defaultViewTypeDesktop: ViewType.GALLERY
    },
    modifier: 'mobile-desktop',
    searchName: 'plot',
    searchbar: {
      autocompleteEndpoint: '/api/classifieds/query/',
      usesNuxtLinks: true
    },
    isMapSearchBtnVisible: true,
    galleryLgPerRow: 4,
    isCompareButtonVisible: true,
    displayAds: true
  },
  rootParams: {
    category: [CategoryId.PLOT]
  }
})
