import { Context } from '@nuxt/types'
import CacheService from '~/services/CacheService'
import CookiesService from '~/services/CookiesService'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ServerResponse } from 'http'
import {
  appendToResponseHeader,
  getHashableFromConfig,
  preprocessParams
} from '~/utils/http'
// @ts-ignore
import hash from 'object-hash'
import { deepJsonCopy } from '~/utils/object'
import qs from 'qs'
import { defaultQsStringifyOptions } from '~/constants/http'
import { USER_HEADER_COOKIE_NAME } from '~/constants/cookies'
import {
  FIVE_MINUTES_MS,
  ONE_HOUR_MS,
  ONE_MINUTE_MS,
  TEN_MINUTES_MS
} from '~/constants/duration'

interface CacheableUrl {
  maxAge: number
  regex: RegExp
}

const cacheableUrls: readonly CacheableUrl[] = Object.freeze([
  {
    regex: /\/api\/clsfds\/search\//,
    maxAge: ONE_HOUR_MS
  },
  {
    regex: /\/api\/(\d+)\/$/,
    maxAge: ONE_MINUTE_MS
  },
  {
    regex: /\/api\/qna\/categories\//,
    maxAge: TEN_MINUTES_MS
  },
  {
    regex: /\/api\/qna\/search\//,
    maxAge: TEN_MINUTES_MS
  },
  {
    regex: /\/api\/discounts\/available\//,
    maxAge: TEN_MINUTES_MS
  },
  {
    regex: /\/api\/classifieds\/text-search\//,
    maxAge: FIVE_MINUTES_MS
  }
])

const proxyHeadersIgnore = [
  'accept',
  'host',
  'x-forwarded-host',
  'x-forwarded-port',
  'x-forwarded-proto',
  'cf-ray',
  'cf-connecting-ip',
  'content-length',
  'content-md5',
  'content-type'
]

export default function(
  { route, req, app, res, $config }: Context,
  inject: any
) {
  const {
    public: { backendApiBaseUrl, backendApiBaseUrlFromClient }
  } = $config

  const serverBaseUrl = backendApiBaseUrl || 'http://localhost:8080'
  const clientBaseUrl = backendApiBaseUrlFromClient || '/'

  const axiosOptions: AxiosRequestConfig = {
    baseURL: process.server ? serverBaseUrl : clientBaseUrl,
    headers: {
      common: {}
    }
  }

  if (process.server) {
    // Don't accept brotli encoding because Node can't parse it
    axiosOptions.headers.common['accept-encoding'] = 'gzip, deflate'

    // Proxy SSR request headers
    if (req?.headers) {
      const reqHeaders = { ...req.headers }

      for (const h of proxyHeadersIgnore) {
        delete reqHeaders[h]
      }

      axiosOptions.headers.common = {
        ...reqHeaders,
        ...axiosOptions.headers.common
      }
    }
  }

  const axiosInstance = axios.create(axiosOptions)

  axiosInstance.interceptors.request.use(config =>
    onRequest(
      config,
      route,
      req,
      app.$requestContainer.resolve(CacheService),
      app.$requestContainer.resolve(CookiesService)
    )
  )

  axiosInstance.interceptors.response.use(axiosResponse =>
    onResponse(axiosResponse, app.$requestContainer.resolve(CacheService), res)
  )

  inject('axios', axiosInstance)
}

function onResponse(
  axiosResponse: AxiosResponse,
  cache: CacheService,
  nodeResponse: ServerResponse
) {
  if (process.client) {
    attemptToSetCache(axiosResponse, cache)
  } else if (process.server) {
    injectBackendCookieHeaders(axiosResponse, nodeResponse)
  }
  return axiosResponse
}

function injectBackendCookieHeaders(
  axiosResponse: AxiosResponse,
  nodeResponse: ServerResponse
) {
  if (
    // Only inject /api/ requests headers, exclude external services
    axiosResponse.config.url?.startsWith('/api/') &&
    axiosResponse.headers['set-cookie']
  ) {
    appendToResponseHeader(
      nodeResponse,
      'set-cookie',
      axiosResponse.headers['set-cookie']
    )
  }
}

function onRequest(
  config: AxiosRequestConfig,
  route: Context['route'],
  req: Context['req'],
  cache: CacheService,
  cookies: CookiesService
): AxiosRequestConfig {
  config.params = modifyParams({ params: config.params, route, req, cookies })
  config.headers = modifyHeaders({
    headers: config.headers,
    req,
    cookies,
    url: config.url
  })
  config.paramsSerializer = params =>
    qs.stringify(params, defaultQsStringifyOptions)

  if (cookies.get('ait')) {
    // audits iframe token
    let tokenToUse = cookies.get('ait')

    if (req?.headers['access-token']) {
      // if we have an access token in request headers then use that
      tokenToUse = req?.headers['access-token'] as string
    }
    config.headers.common['Access-Token'] = tokenToUse
  }

  if (process.client) {
    const cachedConfig = attemptToGetFromCache(config, cache)
    if (cachedConfig) {
      config = cachedConfig
    }
  }
  return config
}

function modifyParams({
  params,
  route,
  req,
  cookies
}: {
  params: AxiosRequestConfig['params']
  route: Context['route']
  req: Context['req']
  cookies: CookiesService
}): AxiosRequestConfig['params'] {
  // preprocess params, removes null values etc
  params = params ? preprocessParams(params) : {}
  // set the language param
  const langParam = route.query && route.query.lang
  if (langParam) {
    params.lang = langParam
  } else if (req) {
    const langCookie = cookies.get('lang')
    if (langCookie) {
      params.lang = langCookie
    }
    if (process.env.NODE_ENV === 'production') {
      delete params._test
    }
  }
  return params
}

function attemptToGetFromCache(
  config: AxiosRequestConfig,
  cache: CacheService
) {
  const urlIsCacheable = cacheableUrls.some(
    url => config.url && url.regex.test(config.url)
  )
  if (urlIsCacheable && cache) {
    const hashable = getHashableFromConfig(config)
    const key = hash(hashable)
    if (cache.has(key)) {
      const response: AxiosResponse = cache.get(key)
      // @ts-ignore
      config.adapter = () =>
        // Set the request adapter to send the cached response
        // and prevent the request from actually running
        // @ts-ignore
        Promise.resolve({
          data: response.data,
          status: response.status,
          statusText: response.statusText,
          header: response.headers,
          config: response,
          response
        })
      return config
    }
  }
}

function modifyHeaders({
  headers,
  req,
  cookies,
  url
}: {
  headers: AxiosRequestConfig['headers']
  req: Context['req']
  cookies: CookiesService
  url?: string
}) {
  const isExternalHost = url && !url.startsWith('/')
  if (isExternalHost) {
    return headers
  }

  let appHost

  if (req) {
    appHost = req.headers.host
  } else if (process.client && typeof window !== 'undefined') {
    appHost = window.location.hostname
  }
  headers.common['Request-Domain'] = appHost

  headers.common.site = 'car'

  if (process.env.NODE_ENV === 'development') {
    const userHeaderCookie = cookies.get(USER_HEADER_COOKIE_NAME)
    if (userHeaderCookie) {
      headers.common.user = userHeaderCookie
    }
  }

  return headers
}

function attemptToSetCache(response: AxiosResponse, cache: CacheService): void {
  const cacheableUrl = cacheableUrls.find(
    url => response.config.url && url.regex.test(response.config.url)
  )
  if (cacheableUrl) {
    const hashable = getHashableFromConfig(response.config)
    const key = hash(hashable)
    cache.set(
      key,
      deepJsonCopy({
        data: response.data
      }),
      cacheableUrl.maxAge
    )
  }
}
