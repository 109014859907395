import Vue from 'vue'
import FloatingVue from 'floating-vue'

// Global options
export default () => {
  const options = {
    themes: {
      'dropdown-sm': {
        $extend: 'dropdown'
      },
      'dropdown-md': {
        $extend: 'dropdown'
      },
      'dropdown-lg': {
        $extend: 'dropdown'
      },
      'tooltip-light': {
        $extend: 'tooltip'
      },
      popover: {
        $extend: 'tooltip'
      }
    },
    arrowPadding: 8,
    defaultBoundariesElement: document.body
  }

  Vue.use(FloatingVue, options)
}
