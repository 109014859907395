import { Path } from '~/icons/types'

export function ciIconToSvg(ciIcon: any) {
  const paths = ciIcon.paths.map(
    (p: Path) =>
      `<path fill-rule="${p.clipRule}" fill-rule="${
        p.fillRule
      }" fill="${p.fill || 'currentColor'}" d="${p.d}" class=""></path>`
  )

  return `<svg aria-hidden="true" focusable="false" data-prefix="${ciIcon.prefix}" data-icon="${ciIcon.iconName}" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${ciIcon.width} ${ciIcon.height}" class="tw-mr-2 tw-ml-2 tw-text-lg tw-w-5 tw-inline-block ci-${ciIcon.name}">${paths}</svg>`
}
