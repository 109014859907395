import { ActionTreeWithRootState } from '~/store/types'
import {
  RESET_DATE_FACETS,
  RESET_FACETS,
  RESET_SECTION_FILTERS,
  RESET_SUB_FILTERS,
  SET_DATE_AVAILABLE_FROM,
  SET_DATE_RANGE_PRESETS,
  SET_ERROR,
  SET_FACET,
  SET_MARKET_SECTIONS,
  SET_MONTHS_AVAILABLE,
  SET_OUTLETS_DATA,
  SET_PROCESS_IS_PENDING,
  SET_SUB_FILTER,
  SET_USER_CATEGORIES_ROOT,
  SET_USER_SECTIONS
} from '~/store/modules/shared/classifieds-analytics/mutation-types'
import {
  CATEGORY_PARAM_NAME,
  DATE_PRESET_PARAM_NAME,
  DATE_FROM_PARAM_NAME,
  DATE_TO_PARAM_NAME,
  QUERY_PARAM_NAME,
  QUERY_PARAM_SHOP,
  LAST_MONTH_DATE_PRESET_NAME
} from '~/constants/analytics/classifieds'
import { getTimestamp } from '~/utils/date'
import { DateRangePreset } from '~/models/date/types'
import { fromUnixTime } from 'date-fns'
import RouterService from '~/services/RouterService'
import { fromBase64, toBase64 } from '~/utils/object'
import ClassifiedsAnalyticsService from '~/services/analytics/classifieds/ClassifiedsAnalyticsService'
import ClassifiedsAnalyticsDateRangeService from '~/services/analytics/classifieds/ClassifiedsAnalyticsDateRangeService'
import { ClassifiedsAnalyticsState } from '~/store/modules/shared/classifieds-analytics/state'

export default {
  selectDateRangePreset({ commit }, { from, to, name }: DateRangePreset) {
    const routerService = this.$dep(RouterService)
    commit(SET_FACET, { name: 'dateFrom', value: from })
    commit(SET_FACET, { name: 'dateTo', value: to })

    routerService.updateQueryParams({
      [DATE_FROM_PARAM_NAME]: null,
      [DATE_TO_PARAM_NAME]: null,
      [DATE_PRESET_PARAM_NAME]: name
    })
  },
  setCategory({ commit }, category: number[]) {
    commit(SET_FACET, { name: 'category', value: category })
    const routerService = this.$dep(RouterService)

    routerService.updateQueryParams({
      [CATEGORY_PARAM_NAME]: category.map(c => c.toString())
    })
  },
  setQuery({ commit }, query: string) {
    commit(SET_FACET, { name: 'query', value: query })
    const routerService = this.$dep(RouterService)
    return routerService.updateQueryParams({ [QUERY_PARAM_NAME]: query })
  },
  setOutlet({ state, commit }, value: string) {
    const classifiedsAnalyticsService = this.$dep(ClassifiedsAnalyticsService)

    const userSection = state?.userSections?.map(us => ({
      ...us,
      filters: classifiedsAnalyticsService.setOutletInFilters(
        state?.outletsData?.filterKey,
        value,
        us.filters
      )
    }))

    commit(SET_USER_SECTIONS, userSection)

    const routerService = this.$dep(RouterService)

    routerService.updateQueryParams({
      [QUERY_PARAM_SHOP]: value
    })
  },
  setDate(
    { state, commit },
    { name, value }: { name: string; value: Date; queryName: string }
  ) {
    commit(SET_FACET, { name, value })
    const { dateFrom, dateTo } = state.facets
    const routerService = this.$dep(RouterService)
    routerService.updateQueryParams({
      [DATE_FROM_PARAM_NAME]: dateFrom && getTimestamp(dateFrom).toString(),
      [DATE_TO_PARAM_NAME]: dateTo && getTimestamp(dateTo).toString(),
      [DATE_PRESET_PARAM_NAME]: null
    })
  },
  async clearFacets({ dispatch, commit, state }) {
    const subFiltersNames = Object.keys(state.subFilters)
    const routerService = this.$dep(RouterService)

    commit(RESET_FACETS)
    commit(RESET_SUB_FILTERS)
    commit(RESET_SECTION_FILTERS)
    await dispatch('setDefaultDatePickerFacets')
    await routerService.updateQueryParams({
      [DATE_FROM_PARAM_NAME]: null,
      [DATE_TO_PARAM_NAME]: null,
      [DATE_PRESET_PARAM_NAME]: null,
      [QUERY_PARAM_NAME]: null,
      [CATEGORY_PARAM_NAME]: null,
      [QUERY_PARAM_SHOP]: null,
      // transforms ['sf-15000-category', 'sf-15000-make'] sub filters names
      // to { 'sf-15000-category': null, 'sf-15000-make': null } so that they are removed from the query params
      ...subFiltersNames.reduce((s, v) => ({ ...s, [v]: null }), {})
    })
    dispatch('loadOverviewData')
  },
  clearDateFacets({ commit }) {
    commit(RESET_DATE_FACETS)
    const routerService = this.$dep(RouterService)
    routerService.updateQueryParams({
      [DATE_FROM_PARAM_NAME]: null,
      [DATE_TO_PARAM_NAME]: null,
      [DATE_PRESET_PARAM_NAME]: null
    })
  },
  async setFacetsDefaultValues({ dispatch, state, commit, getters }) {
    const { query }: { query: Record<string, any> } = this.$router.currentRoute

    for (const [key, value] of Object.entries(query)) {
      switch (key) {
        case QUERY_PARAM_NAME: {
          commit(SET_FACET, { name: 'query', value })
          break
        }
        case CATEGORY_PARAM_NAME: {
          Array.isArray(value)
            ? commit(SET_FACET, {
                name: 'category',
                value: value.map(v => Number(v))
              })
            : commit(SET_FACET, {
                name: 'category',
                value: [Number(value)]
              })
          break
        }
        case DATE_PRESET_PARAM_NAME: {
          const preset = getters.getDateRangePresetByName(value)
          if (preset) {
            const { from, to } = preset
            commit(SET_FACET, { name: 'dateFrom', value: from })
            commit(SET_FACET, { name: 'dateTo', value: to })
          }
          break
        }
        case DATE_FROM_PARAM_NAME: {
          !state.facets.dateFrom &&
            commit(SET_FACET, { name: 'dateFrom', value: fromUnixTime(value) })
          break
        }
        case DATE_TO_PARAM_NAME: {
          !state.facets.dateTo &&
            commit(SET_FACET, { name: 'dateTo', value: fromUnixTime(value) })
          break
        }
        case state.outletsData && state.outletsData.urlArgName: {
          commit(SET_FACET, { name: 'selectedOutlet', value })
        }
      }
    }
    // If no date is provided, set last month preset
    if (!state.facets.dateFrom && !state.facets.dateTo) {
      await dispatch('setDefaultDatePickerFacets')
    }
  },
  setDefaultDatePickerFacets({ commit, getters }) {
    const lastMonthPreset = getters.getDateRangePresetByName(
      LAST_MONTH_DATE_PRESET_NAME
    )
    if (lastMonthPreset) {
      const { from, to } = lastMonthPreset
      commit(SET_FACET, { name: 'dateFrom', value: from })
      commit(SET_FACET, { name: 'dateTo', value: to })
    }
  },
  setSubFiltersDefaultValues({ commit }) {
    const { query }: { query: Record<string, any> } = this.$router.currentRoute
    for (const [name, value] of Object.entries(query)) {
      // this could be a weak check
      if (name.startsWith('sf') && name !== 'sf-shop') {
        commit(SET_SUB_FILTER, {
          name,
          value: fromBase64(decodeURIComponent(value))
        })
      }
    }
  },
  changeSubFilter({ commit, dispatch }, { name, value }) {
    const routerService = this.$dep(RouterService)
    commit(SET_SUB_FILTER, {
      name,
      value
    })
    dispatch('loadOverviewData')
    return routerService.updateQueryParams({
      [name]: value && encodeURIComponent(toBase64(value))
    })
  },
  async loadOverviewData({ state, commit }) {
    const classifiedsAnalyticsService = this.$dep(ClassifiedsAnalyticsService)
    const { query }: { query: Record<string, any> } = this.$router.currentRoute
    const {
      marketSections,
      userSections,
      monthsAvailable,
      dateAvailableFrom,
      userCategoriesRoot,
      outlets,
      processIsPending
    } = await classifiedsAnalyticsService.getOverview(state.subFilters, query)
    commit(SET_USER_SECTIONS, userSections)
    commit(SET_MARKET_SECTIONS, marketSections)
    commit(SET_MONTHS_AVAILABLE, monthsAvailable)
    commit(SET_DATE_AVAILABLE_FROM, dateAvailableFrom)
    commit(SET_USER_CATEGORIES_ROOT, userCategoriesRoot)
    commit(SET_PROCESS_IS_PENDING, processIsPending)
    commit(SET_OUTLETS_DATA, outlets)
  },
  async initializePage({ state, commit, dispatch }) {
    const classifiedsAnalyticsDateRangeService = this.$dep(
      ClassifiedsAnalyticsDateRangeService
    )
    try {
      await dispatch('setSubFiltersDefaultValues')
      await dispatch('loadOverviewData')
      commit(
        SET_DATE_RANGE_PRESETS,
        classifiedsAnalyticsDateRangeService.getDateRangePresets(
          state.monthsAvailable
        )
      )
      await dispatch('setFacetsDefaultValues')
    } catch (e) {
      if ((e as any).response) {
        const { status, error: message } = (e as any).response.data
        commit(SET_ERROR, { status, message })
        return
      }
      commit(SET_ERROR, { status: 500 })
    }
  }
} as ActionTreeWithRootState<ClassifiedsAnalyticsState>
