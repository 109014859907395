import { TranslateResult } from 'vue-i18n'

export interface Option {
  /* @deprecated use the label field instead */
  text?: string | TranslateResult
  name?: string
  headerText?: string | TranslateResult
  value: string | number
  count?: number | null
  disabled?: boolean
  isTop?: boolean
  label?: string | TranslateResult
  selected?: boolean
  options?: Option[]
  key?: string
}

export interface TreeOption {
  label?: string
  name?: string
  hasChildren: boolean
  value: number
  selected: boolean
  count?: number
  redirectUrl?: string
  children: TreeOption[]
}

export interface BackendOption {
  name: string
  value: string | number
}

export enum ViewType {
  LIST = 'list',
  GALLERY = 'gallery'
}

export enum I18nLocale {
  EL = 'el',
  EN = 'en',
  DE = 'de',
  BG = 'bg'
}

export type StringMap<T> = Map<string, T>

export interface Postcode {
  country: Country
  postcode: {
    id: string | null
    name: string
  }
}

export interface Telephone {
  code: number
  dontShowToPublic?: boolean
  telephone: string
  social: Social
}

export interface Country {
  code: string
  id: number
  name: string
}

export interface Social {
  viber: boolean
  whatsapp: boolean
}

export interface AvailableLocale {
  locale: I18nLocale
  title: string
  icon: string
  link: string
}

export interface StaticFilesResult {
  staticUrl: string
  files: [StaticFile]
}

export interface StaticFile {
  path: string
  filename: string
}

export interface Step {
  disabled: boolean
  label: string
  selected: boolean
  vertical: boolean
  round: boolean
  completed: boolean
}
