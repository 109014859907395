import '~/polyfills/reflect-metadata' // Reflection API polyfill required by tsyringe
import RouteGuardService from '~/services/RouteGuardService'
import { ClientFlashMessageType } from '~/models/flash/types'
import FlashService from '~/services/flash/FlashService'

export default function({ $dep, query, redirect, $i18n, $config }: any) {
  const routeGuardService = $dep(RouteGuardService)
  if (routeGuardService.userIsLoggedIn()) {
    if (query.gotonext && !query.gotonext.includes('gotonext')) {
      const {
        public: { domain }
      } = $config

      const allowedDomains = [domain, 'car.gr']

      const gotonext = query.gotonext.trim()

      if (gotonext.startsWith('/') && !gotonext.startsWith('//')) {
        return redirect(gotonext)
      } else {
        try {
          const parsedUrl = new URL(gotonext)
          let hostname = parsedUrl.hostname.toLowerCase()

          if (hostname.startsWith('www.')) {
            hostname = hostname.substring(4)
          }

          if (allowedDomains.includes(hostname)) {
            return redirect(parsedUrl.href)
          }
        } catch (_e) {}
      }
    }

    const flashService = $dep(FlashService)
    flashService.redirectWithFlash(
      '/',
      ClientFlashMessageType.ALREADY_LOGGED_IN,
      {
        showAsSnackbar: true,
        snackbarVariant: 'error',
        text: $i18n.t('you are already logged in')
      }
    )
  }
}
