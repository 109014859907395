import { CIcon } from '~/icons/types'

export const ciCheckCircle: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 2.398c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6Zm4.4 8-4.8 4.8c-.5.5-1.2.5-1.7 0l-2.4-2.4c-.2-.2-.4-.6-.4-.9 0-.3.1-.6.4-.9.5-.5 1.2-.5 1.7 0l1.6 1.6 4-4c.5-.5 1.2-.5 1.7 0 .2.2.3.6.3.9 0 .3-.1.7-.4.9Z',
      fill: 'currentColor'
    },
    {
      d:
        'm14.8 8.7-4 4-1.6-1.6c-.5-.5-1.2-.5-1.7 0-.2.2-.4.5-.4.8 0 .3.1.6.4.9l2.4 2.4c.5.5 1.2.5 1.7 0l4.8-4.8c.2-.2.4-.5.4-.8 0-.3-.1-.6-.3-.9-.5-.5-1.2-.5-1.7 0Z',
      fill: 'transparent'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'check-circle',
  type: 'solid'
}
