import { CIcon } from '~/icons/types'

export const ciAngleRight: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M16.06 10.94a1.5 1.5 0 0 1 0 2.12l-5 5a1.5 1.5 0 0 1-2.12-2.12L12.878 12l-3.94-3.94a1.5 1.5 0 1 1 2.122-2.12l5 5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'angle-right',
  type: 'solid'
}
