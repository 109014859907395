import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'
import { CategoryId } from '~/models/category/types'

export const rentalsSearchConfig: SearchConfig = Object.freeze({
  settings: {
    searchName: 'rentals',
    viewType: {
      availableViewTypes: [ViewType.LIST],
      defaultViewTypeMobile: ViewType.LIST,
      defaultViewTypeDesktop: ViewType.LIST
    },
    modifier: 'mobile-desktop',
    searchbar: {
      autocompleteEndpoint: '/api/classifieds/query/',
      usesNuxtLinks: false
    },
    isJobsSearch: true,
    isCompareButtonVisible: false,
    isMapSearchBtnVisible: true,
    displayAds: true
  },
  rootParams: {
    category: [CategoryId.RENTALS]
  }
})
