import { interopDefault } from '~/router/utils'

export const adminAuditsRoute = [
  {
    name: '__admin_audits',
    path: '/admin/audits/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/admin/audits.vue' /* webpackChunkName: "pages-admin-audits" */
        )
      )
  }
]
