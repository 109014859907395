import { interopDefault } from '~/router/utils'

export const playgroundCompositionsRoutes = [
  {
    name: '__playground_compositions',
    path: '/playground/compositions',
    redirect: { name: '__playground' }
  },
  {
    name: '__playground_compositions_use_focus',
    path: '/playground/compositions/use-focus',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/compositions/use-focus.vue')
      )
  }
]
