import { ViewType } from '~/models/shared/types'
import { SearchConfig } from '~/models/search/config'

export const userClassifiedsSearchConfig = {
  create(userId: string): SearchConfig {
    return {
      settings: {
        viewType: {
          availableViewTypes: [ViewType.LIST, ViewType.GALLERY],
          defaultViewTypeDesktop: ViewType.GALLERY,
          defaultViewTypeMobile: ViewType.GALLERY
        },
        searchName: 'user-profile',
        searchbar: {
          autocompleteEndpoint: '/api/classifieds/profile/query/',
          usesNuxtLinks: true
        },
        displayAds: true
      },
      rootParams: {
        sort: ['dm'],
        profile: [userId],
        category: [20001]
      }
    }
  }
}
