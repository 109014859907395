import { DateRangePreset } from '~/models/date/types'
import {
  AnalyticsOutlets,
  AnalyticsSection
} from '~/models/analytics/classifieds'
import { CategoryId } from '~/models/category/types'

interface FacetsKeys {
  [key: string]: string | number[] | null | Date
}

interface Facets extends FacetsKeys {
  query: string
  dateFrom: Date | null
  dateTo: Date | null
  category: number[] | null
}

export interface SectionFilter {
  sectionType: string
  filterName: string
  filterValue: string
  filterLabel: string
}

export interface ClassifiedsAnalyticsState {
  facets: Facets
  sectionFilters: SectionFilter[]
  dateRangePresets: DateRangePreset[]
  marketSections: AnalyticsSection[] | null
  userSections: AnalyticsSection[] | null
  subFilters: Record<string, any>
  monthsAvailable: number
  userCategoriesRoot: CategoryId
  dateAvailableFrom: string
  outletsData: AnalyticsOutlets | null
  processIsPending: boolean
  error: { status: number; message?: string } | null
}

export const CLASSIFIEDS_ANALYTICS_NS = 'classifiedsAnalytics'

export function getDefaultFacetValues(): Facets {
  return {
    query: '',
    dateFrom: null,
    dateTo: null,
    category: null
  }
}

export default function getDefaultState(): ClassifiedsAnalyticsState {
  return {
    facets: getDefaultFacetValues(),
    dateRangePresets: [],
    marketSections: null,
    userSections: null,
    subFilters: {},
    sectionFilters: [],
    monthsAvailable: 0,
    userCategoriesRoot: CategoryId.CLASSIFIEDS,
    dateAvailableFrom: '',
    outletsData: null,
    processIsPending: false,
    error: null
  }
}
