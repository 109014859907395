import { CIcon } from '~/icons/types'

export const ciCamera: CIcon = {
  width: '24',
  height: '24',
  paths: [
    { d: 'M12 16.6A3.8 3.8 0 1 0 12 9a3.8 3.8 0 0 0 0 7.6Z' },
    {
      d:
        'M20.1 6.7c-.4-.1-.8-.1-1.2-.2-.5-.1-.9-.3-1.1-.7L17 4.5c-.5-.8-1.4-1.3-2.3-1.4C13.8 3 12.9 3 12 3c-.9 0-1.8 0-2.7.1-.9 0-1.8.6-2.3 1.4l-.8 1.3c-.2.4-.7.6-1.1.7-.4 0-.8.1-1.2.2-1.4.2-2.4 1.5-2.4 2.9V18c0 1.7 1.3 3 3 3h15c1.7 0 3-1.3 3-3V9.6c0-1.4-1-2.7-2.4-2.9ZM12 18c-2.9 0-5.2-2.4-5.2-5.2 0-2.8 2.3-5.3 5.2-5.3 2.9 0 5.2 2.4 5.2 5.2 0 2.8-2.3 5.3-5.2 5.3Zm6.8-6.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8-.4.8-.8.8Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'camera',
  type: 'solid'
}
