import { interopDefault } from '~/router/utils'
import { getParkingRoutes } from '~/router/routes/shared/account/account-parking'
import { RouteConfig } from 'vue-router'
import wantedPartsRoutes from '~/router/routes/car/wanted-parts'

/**
 *
 * @param basePath
 */
export function getAccountRoutes(
  basePath: string,
  parkingPath: string = 'parking',
  notSharedChildren: any[]
): RouteConfig[] {
  return [
    {
      name: '__account',
      path: basePath,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/index.vue' /* webpackChunkName: "pages-shared-account-index.vue" */
          )
        ),
      meta: {
        scrollTopSmooth: true
      },
      children: [
        {
          path: 'overview/',
          name: '__account_overview',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/overview.vue' /* webpackChunkName: "pages-shared-account-overview.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        {
          path: 'announcements/',
          name: '__account_announcements',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/announcements/index.vue' /* webpackChunkName: "pages-shared-account-index.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        {
          path: 'announcements/:id/',
          name: '__account_announcements_view',
          meta: { scrollTopSmooth: true },
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/announcements/view.vue' /* webpackChunkName: "pages-shared-account-view.vue" */
              )
            )
        },
        {
          path: 'tickets/',
          name: '__account_tickets',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/tickets/list.vue' /* webpackChunkName: "pages-shared-account-tickets-list.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        {
          path: 'offers/',
          name: '__account_offers',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/offers/index.vue' /* webpackChunkName: "pages-shared-account-offers-index.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          },
          redirect: { name: '__account_offers_other_classifieds' },
          children: [
            {
              path: 'my-classifieds/',
              name: '__account_offers_my_classifieds',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/offers/my-classifieds.vue' /* webpackChunkName: "pages-shared-account-offers-my-classifieds.vue" */
                  )
                )
            },
            {
              path: 'other-classifieds/',
              name: '__account_offers_other_classifieds',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/offers/other-classifieds.vue' /* webpackChunkName: "pages-shared-account-offers-other-classifieds.sent" */
                  )
                ),
              meta: {
                ignoreScrollBehavior: true
              }
            }
          ]
        },
        {
          path: 'tickets/:id/',
          name: '__account_tickets_view',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/tickets/view.vue' /* webpackChunkName: "pages-shared-account-tickets-list.vue" */
              )
            )
        },
        {
          path: 'statistics/',
          name: '__account_statistics',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/statistics/index.vue' /* webpackChunkName: "pages-shared-account-statistics-index.vue" */
              )
            ),
          redirect: { name: '__account_statistics_overview' },
          meta: {
            scrollTopSmooth: true
          },
          children: [
            {
              path: 'overview/',
              name: '__account_statistics_overview',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/analytics/overview.vue' /* webpackChunkName: "pages-analytics-overview.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'market/',
              name: '__account_statistics_market',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/analytics/market.vue' /* webpackChunkName: "pages-analytics-market.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'classifieds/',
              name: '__account_statistics_classifieds',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/analytics/classifieds/index.vue' /* webpackChunkName: "pages-analytics-classifieds.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            }
          ]
        },
        {
          path: 'searches/',
          name: '__account_searches',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/searches/index.vue' /* webpackChunkName: "pages-shared-account-parking-searches-index.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          },
          redirect: { name: '__account_searches_favorite' },
          children: [
            {
              path: 'favorite/',
              name: '__account_searches_favorite',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/searches/favorite.vue' /* webpackChunkName: "pages-shared-account-searches-favorite.vue" */
                  )
                ),
              meta: {
                ignoreScrollBehavior: true
              }
            },
            {
              path: 'recent-classifieds/',
              name: '__account_searches_recent_classifieds',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/searches/recent-classifieds.vue' /* webpackChunkName: "pages-shared-account-searches-recent-classifieds.vue" */
                  )
                ),
              meta: {
                ignoreScrollBehavior: true
              }
            },

            {
              path: 'recent/',
              name: '__account_searches_recent',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/searches/recent.vue' /* webpackChunkName: "pages-shared-account-searches-recent.vue" */
                  )
                ),
              meta: {
                ignoreScrollBehavior: true
              }
            }
          ]
        },
        {
          path: `${parkingPath}/`,
          name: '__account_parking',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/parking/index.vue' /* webpackChunkName: "pages-shared-account-classifieds-index.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          },
          redirect: { name: '__account_parking_classifieds_favorite' },
          children: getParkingRoutes('account')
        },
        {
          path: 'messages/',
          name: '__account_messages',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/messages.vue' /* webpackChunkName: "pages-shared-account-messages.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          },
          children: [
            {
              path: 'inbox/',
              name: '__account_messages_inbox',
              redirect: { name: '__account_messages' }
            }
          ]
        },
        {
          path: 'sale-requests/',
          name: '__account_sale_requests',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/sale-requests/index.vue' /* webpackChunkName: "pages-shared-account-sale-requests-index.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          },
          redirect: { name: '__account_sale_requests_overview' },
          children: [
            {
              path: 'overview/',
              name: '__account_sale_requests_overview',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/sale-requests/overview.vue' /* webpackChunkName: "pages-shared-account-sale-requests-overview.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'request-settings/',
              name: '__account_sale_requests_request_settings',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/sale-requests/request-settings.vue' /* webpackChunkName: "pages-shared-account-sale-requests-request-settings.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'general-settings/',
              name: '__account_sale_requests_general_settings',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/sale-requests/general-settings.vue' /* webpackChunkName: "pages-shared-account-sale-requests-general-settings.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            }
          ]
        },
        {
          path: 'rogue-classifieds/',
          name: '__account_rogue_classifieds',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/rogue-classifieds.vue' /* webpackChunkName: "pages-shared-account-rogue-classifieds.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        {
          path: 'rogue-classifieds/info',
          name: '__account_rogue_classifieds_info',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/rogue-classifieds-info.vue' /* webpackChunkName: "pages-shared-account-rogue-classifieds-info.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        {
          path: 'settings/',
          name: '__account_settings',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/settings/index.vue' /* webpackChunkName: "pages-shared-account-settings-index.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          },
          children: [
            {
              path: 'details/',
              name: '__account_settings_details',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/settings/details.vue' /* webpackChunkName: "pages-shared-account-settings-details.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'dealers/',
              name: '__account_settings_dealers',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/car/account/dealers/index.vue' /* webpackChunkName: "pages-car-account-dealers-index.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              name: '__account_settings_dealers_edit',
              path: 'dealers/:dealerId/edit/',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/car/account/dealers/edit.vue' /* webpackChunkName: "pages-dealers-edit.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'manager-invoices/',
              name: '__account_settings_manager_invoices',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/settings/manager-invoices.vue' /* webpackChunkName: "pages-shared-account-settings-manager-invoices.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'security/',
              name: '__account_settings_security',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/settings/security.vue' /* webpackChunkName: "pages-shared-account-settings-security.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'email-change/',
              name: '__account_settings_email_change',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/settings/email-change.vue' /* webpackChunkName: "pages-shared-account-settings-email-change.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'invoicing-details/',
              name: '__account_settings_invoicing_details',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/settings/invoicing-details.vue' /* webpackChunkName: "pages-shared-account-settings-invoicing-details.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'payments/',
              name: '__account_settings_payments',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/settings/payments/index.vue' /* webpackChunkName: "pages-shared-account-settings-payments-index.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              },
              redirect: { name: '__account_settings_payments_overview' },
              children: [
                {
                  path: 'overview/',
                  name: '__account_settings_payments_overview',
                  component: () =>
                    interopDefault(
                      import(
                        '~/pages/shared/account/settings/payments/overview.vue' /* webpackChunkName: "pages-shared-account-settings-payments-overview.vue" */
                      )
                    ),
                  meta: {
                    scrollTopSmooth: true
                  }
                },
                {
                  path: 'success/',
                  name: '__account_settings_payments_success',
                  component: () =>
                    interopDefault(
                      import(
                        '~/pages/shared/account/settings/payments/success.vue' /* webpackChunkName: "pages-shared-account-settings-payments-success.vue" */
                      )
                    ),
                  meta: {
                    scrollTopSmooth: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'subscriptions/',
          name: '__account_subscriptions',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/subscriptions.vue' /* webpackChunkName: "pages-shared-account-subscriptions.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        {
          path: 'credits/',
          name: '__account_credits',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/credits.vue' /* webpackChunkName: "pages-shared-account-credits.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        {
          path: 'notifications/',
          name: '__account_notifications',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/notifications/index.vue' /* webpackChunkName: "pages-shared-account-notifications-index.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          },
          redirect: { name: '__account_notifications_list' },
          children: [
            {
              path: 'list/',
              name: '__account_notifications_list',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/notifications/list.vue' /* webpackChunkName: "pages-shared-account-notifications-list.vue" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            },
            {
              path: 'settings/',
              name: '__account_notifications_settings',
              component: () =>
                interopDefault(
                  import(
                    '~/pages/shared/account/settings/notifications.vue' /* webpackChunkName: "pages-settings-notifications" */
                  )
                ),
              meta: {
                scrollTopSmooth: true
              }
            }
          ]
        },
        {
          path: 'audits/new',
          name: '__account_audits_new',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/audits/new.vue' /* webpackChunkName: "pages-shared-account-audits-new.vue" */
              )
            )
        },
        {
          path: 'xml',
          name: '__account_xml',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/xml.vue' /* webpackChunkName: "pages-shared-account-xml.vue" */
              )
            )
        },
        ...wantedPartsRoutes,
        {
          path: 'prices-history',
          name: '__account_prices_history',
          component: () =>
            interopDefault(
              import(
                '~/pages/shared/account/subscription-perks/prices-history.vue' /* webpackChunkName: "pages-shared-account-subscription-perks-prices-history.vue" */
              )
            ),
          meta: {
            scrollTopSmooth: true
          }
        },
        ...notSharedChildren
      ]
    }
  ]
}
