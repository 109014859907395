import { interopDefault } from '~/router/utils'

export const playgroundComponentsRoutes = [
  {
    name: '__playground_components',
    path: '/playground/components',
    redirect: { name: '__playground' }
  },
  {
    name: '__playground_components_custom_select',
    path: '/playground/components/custom-select',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/custom-select.vue')
      )
  },
  {
    name: '__playground_components_shimmer',
    path: '/playground/components/shimmer',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/shimmer.vue'))
  },
  {
    name: '__playground_components_ads',
    path: '/playground/components/ads',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/ads.vue'))
  },
  {
    name: '__playground_components_link',
    path: '/playground/components/link',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/link.vue'))
  },
  {
    name: '__playground_components_alert',
    path: '/playground/components/alert',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/alert.vue'))
  },
  {
    name: '__playground_components_spinner',
    path: '/playground/components/spinner',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/spinner.vue'))
  },
  {
    name: '__playground_components_card',
    path: '/playground/components/card',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/card.vue'))
  },
  {
    name: '__playground_components_tabs',
    path: '/playground/components/tabs',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/tabs.vue'))
  },
  {
    name: '__playground_components_progressbar',
    path: '/playground/components/progressbar',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/progressbar.vue')
      )
  },
  {
    name: '__playground_components_textarea',
    path: '/playground/components/textarea',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/textarea.vue')
      )
  },
  {
    name: '__playground_components_icons',
    path: '/playground/components/icons',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/icons.vue'))
  },
  {
    name: '__playground_components_dropdown',
    path: '/playground/components/dropdown',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/dropdown.vue')
      )
  },
  {
    name: '__playground_components_location_autocomplete_input',
    path: '/playground/components/location-autocomplete-input',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/components/location-autocomplete-input.vue'
        )
      )
  },
  {
    name: '__playground_components_autocomplete_input',
    path: '/playground/components/autocomplete-input',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/autocomplete-input.vue')
      )
  },
  {
    name: '__playground_components_image_gallery',
    path: '/playground/components/image-gallery',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/image-gallery.vue')
      )
  },
  {
    name: '__playground_components_map_location_input',
    path: '/playground/components/map-location-input',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/map-location-input.vue')
      )
  },
  {
    name: '__playground_components_img',
    path: '/playground/components/img',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/img.vue'))
  },
  {
    name: '__playground_components_picture',
    path: '/playground/components/picture',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/picture.vue'))
  },
  {
    name: '__playground_components_modal',
    path: '/playground/components/modal',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/modal.vue'))
  },
  {
    name: '__playground_components_grid',
    path: '/playground/components/grid',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/grid.vue'))
  },
  {
    name: '__playground_components_container',
    path: '/playground/components/container',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/container.vue')
      )
  },
  {
    name: '__playground_components_dropdown_menu',
    path: '/playground/components/dropdown-menu',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/dropdown-menu.vue')
      )
  },
  {
    name: '__playground_components_button_group',
    path: '/playground/components/button-group',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/button-group.vue')
      )
  },
  {
    name: '__playground_components_button',
    path: '/playground/components/button',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/button.vue'))
  },
  {
    name: '__playground_components_badge',
    path: '/playground/components/badge',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/badge.vue'))
  },
  {
    name: '__playground_components_table',
    path: '/playground/components/table',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/table.vue'))
  },
  {
    name: '__playground_components_form_select',
    path: '/playground/components/form-select',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/form-select.vue')
      )
  },
  {
    name: '__playground_components_pagination',
    path: '/playground/components/pagination',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/pagination.vue')
      )
  },
  {
    name: '__playground_components_infinite_scroll',
    path: '/playground/components/infinite-scroll',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/infinite-scroll.vue')
      )
  },
  {
    name: '__playground_components_dynamic_icon',
    path: '/playground/components/dynamic-icon',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/dynamic-icon.vue')
      )
  },
  {
    name: '__playground_components_checkbox',
    path: '/playground/components/checkbox',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/checkbox.vue')
      )
  },
  {
    name: '__playground_components_radio',
    path: '/playground/components/radio',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/radio.vue'))
  },
  {
    name: '__playground_components_stepper',
    path: '/playground/components/stepper',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/stepper.vue'))
  },
  {
    name: '__playground_components_price',
    path: '/playground/components/price',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/price.vue'))
  },
  {
    name: '__playground_components_list_group',
    path: '/playground/components/list-group',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/list-group.vue')
      )
  },
  {
    name: '__playground_components_time_input',
    path: '/playground/components/time-input',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/time-input.vue')
      )
  },
  {
    name: '__playground_components_timeline',
    path: '/playground/components/timeline',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/timeline.vue')
      )
  },
  {
    name: '__playground_components_form_builder_validation',
    path: '/playground/components/validation-form-builder',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/components/validation-form-builder.vue'
        )
      )
  },
  {
    name: '__playground_components_collapse',
    path: '/playground/components/collapse',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/collapse.vue')
      )
  },
  {
    name: '__playground_components_expandable_content',
    path: '/playground/components/expandable-content',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/expandable-content.vue')
      )
  },
  {
    name: '__playground_components_input',
    path: '/playground/components/input',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/input.vue'))
  },
  {
    name: '__playground_components_input_group',
    path: '/playground/components/input-group',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/input-group.vue')
      )
  },
  {
    name: '__playground_components_form_group',
    path: '/playground/components/form-group',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/form-group.vue')
      )
  },
  {
    name: '__playground_components_popover',
    path: '/playground/components/popover',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/popover.vue'))
  },
  {
    name: '__playground_components_new_badge',
    path: '/playground/components/new-badge',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/new-badge.vue')
      )
  },
  {
    name: '__playground_components_datepicker',
    path: '/playground/components/datepicker',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/datepicker.vue')
      )
  },
  {
    name: '__playground_components_datepicker_input',
    path: '/playground/components/datepicker-input',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/datepicker-input.vue')
      )
  },
  {
    name: '__playground_components_pin',
    path: '/playground/components/pin',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/pin.vue'))
  },
  {
    name: '__playground_components_anon_guard',
    path: '/playground/components/anon-guard',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/anon-guard.vue')
      )
  },
  {
    name: '__playground_components_video_player',
    path: '/playground/components/video-player',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/video-player.vue')
      )
  },
  {
    name: '__playground_components_login_modal',
    path: '/playground/components/login-modal',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/login-modal.vue')
      )
  },
  {
    name: '__playground_components_star_rating',
    path: '/playground/components/star-rating',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/star-rating.vue')
      )
  },
  {
    name: '__playground_components_google_maps',
    path: '/playground/components/google-maps',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/google-maps.vue')
      )
  },
  {
    name: '__playground_components_counter',
    path: '/playground/components/counter',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/counter.vue'))
  },
  {
    name: '__playground_components_favorite_button',
    path: '/playground/components/favorite-button',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/favorite-button.vue')
      )
  },
  {
    name: '__playground_components_countdown',
    path: '/playground/components/countdown',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/countdown.vue')
      )
  },
  {
    name: '__playground_components_draggable',
    path: '/playground/components/draggable',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/draggable.vue')
      )
  },
  {
    name: '__playground_components_code_highlight',
    path: '/playground/components/code-highlight',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/code-highlight.vue')
      )
  },
  {
    name: '__playground_components_pay_button',
    path: '/playground/components/pay-button',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/pay-button.vue')
      )
  },
  {
    name: '__playground_components_app_confirmation_modal',
    path: '/playground/app-confirmation-modal',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/playground/components/app-confirmation-modal.vue'
        )
      )
  },
  {
    name: '__playground_components_app_snackbar',
    path: '/playground/components/snackbar',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/snackbar.vue')
      )
  },
  {
    name: '__playground_components_calendar',
    path: '/playground/components/calendar',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/calendar.vue')
      )
  },
  {
    name: '__playground_components_slider',
    path: '/playground/components/slider',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/slider.vue'))
  },
  {
    name: '__playground_components_breadcrumbs',
    path: '/playground/components/breadcrumbs',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/breadcrumbs.vue')
      )
  },
  {
    name: '__playground_components_content_slider',
    path: '/playground/components/content-slider',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/content-slider.vue')
      )
  },
  {
    name: '__playground_components_rich_text_editor',
    path: '/playground/components/rich-text-editor',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/rich-text-editor.vue')
      )
  },
  {
    name: '_playground_components_social_share',
    path: '/playground/components/social-share',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/social-share.vue')
      )
  },
  {
    name: '__playground_components_quick_ads',
    path: '/playground/components/quick-ads',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/quick-ads.vue')
      )
  },
  {
    name: '_playground_components_tree_select',
    path: '/playground/components/tree-select',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/tree-select.vue')
      )
  },
  {
    name: '__playground_components_expandable_info',
    path: '/playground/components/expandable-info',
    component: () =>
      interopDefault(
        import('~/pages/shared/playground/components/expandable-info.vue')
      )
  },
  {
    name: '__playground_components_tooltip',
    path: '/playground/components/tooltip',
    component: () =>
      interopDefault(import('~/pages/shared/playground/components/tooltip.vue'))
  }
]
